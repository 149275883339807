// Imports
import PortfolioList from "../components/portfolio/PortfolioList";
import Banner from "../components/layout/Banner"
import useHttp from "../hooks/useHttp";
import {getFullPortfolio} from "../lib/api"
import {useEffect, useState} from "react";
import LoadingSpinner from "../components/UI/LoadingSpinner"

function Portfolio(){

    const {sendRequest, status, data: loadedPortfolio, error} = useHttp(getFullPortfolio, true);

    useEffect(() => {
        sendRequest();
    }, [sendRequest] );
    if(status === "pending")
    {   return(
        <>
            <div className="centered page">
                <LoadingSpinner />
            </div>
        </>);
    }
    if(error)
    {
        return(
        <>
            <div className="centered focused">
                <p>Error fetching data {error}</p>
            </div>
        </>);
    }
    if(status === "completed" && (!loadedPortfolio || loadedPortfolio.length === 0))
    {
        return(
        <>
            <div className="centered focused">
                <p>No Portfolio items found {error}</p>
            </div>
        </>);
    }
    return(
        <>
            <PortfolioList portfolio={loadedPortfolio} />
        </>

);}

export default Portfolio;