import { createSlice } from "@reduxjs/toolkit"

//Detech if the user already has a Dark theme set
const match = window.matchMedia('(prefers-color-scheme: dark)');
const initialThemeState = {
    bDarkTheme: match.matches,
}

const themeSlice = createSlice({
    name: "theme",
    initialState: initialThemeState,
    reducers: {
        changeTheme(state){
            state.bDarkTheme = !state.bDarkTheme;
        },
    }
});

export const themeActions = themeSlice.actions;
export default themeSlice.reducer;