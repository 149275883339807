// Imports
import { Link, useRouteMatch } from "react-router-dom";

// CSS Styles
import CSS from "./PortfolioItem.module.css";

function PortfolioItem(props){
    const routeMatch = useRouteMatch();
    const urlExtent = `${props.date}${props.projectName.replace(/ /g, "-")}`;
    const link =  props.highlights ? "portfolio" : routeMatch.url;

    //CSS class for the item
    let CSSCategory = "";
    for (let i = 0; i < props.categories.length; i++) 
    {     
        switch(props.categories[i])
        {
            case "Design":
                CSSCategory = CSS.design;
                break;
            case "Animation":
                CSSCategory = CSS.animation;
                break;
            case "Programming":
                CSSCategory = CSS.programming;
                break;
            default: 
                break;
        }
    }

    const cssClasses = `${CSS.item} ${CSSCategory}`;
    return(
       <li className={cssClasses}>
           <Link className={CSS.link} to={`${link}/${urlExtent}`} >
            <figure>
                <img src={props.thumbnail} alt={props.thumbnail}/>
                <figcaption>
                    <h2>{props.projectName}</h2>
                    <p>{props.date} {props.subtitle}</p>
                </figcaption>
           </figure>
           </Link>
       </li>
);}

export default PortfolioItem;