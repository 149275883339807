import { __extends } from "tslib";
import * as React from 'react';
import defaultRouter from './routeToBlock';
import defaultRender from './renderer';
var IS_BROWSER = typeof window === 'object';
var defaultBlocks = {
    dropbox: React.lazy(function () { return import('./blocks/dropbox'); }),
    figma: React.lazy(function () { return import('./blocks/figma'); }),
    gfycat: React.lazy(function () { return import('./blocks/gfycat'); }),
    gist: React.lazy(function () { return import('./blocks/gist'); }),
    gmaps: React.lazy(function () { return import('./blocks/gmaps'); }),
    imgur: React.lazy(function () { return import('./blocks/imgur'); }),
    instagram: React.lazy(function () { return import('./blocks/instagram'); }),
    jsfiddle: React.lazy(function () { return import('./blocks/jsfiddle'); }),
    pdf: React.lazy(function () { return import('./blocks/pdf'); }),
    reactPlayer: React.lazy(function () { return import('./blocks/react-player'); }),
    replit: React.lazy(function () { return import('./blocks/replit'); }),
    simplePlayer: React.lazy(function () { return import('./blocks/react-simple-player'); }),
    soundcloud: React.lazy(function () { return import('./blocks/soundcloud'); }),
    tweet: React.lazy(function () { return import('./blocks/tweet'); }),
    youtube: React.lazy(function () { return import('./blocks/youtube'); }),
};
var renderNull = function () { return null; };
var renderWrap = function (children) { return children; };
var ReactEmbed = (function (_super) {
    __extends(ReactEmbed, _super);
    function ReactEmbed() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {};
        return _this;
    }
    ReactEmbed.getDerivedStateFromProps = function (props) {
        if (!IS_BROWSER)
            return null;
        if (typeof props.url === 'string') {
            try {
                var url = new URL(props.url);
                var hostname = url.hostname, pathname = url.pathname, search = url.search, hash = url.hash;
                return {
                    url: {
                        url: props.url,
                        hostname: hostname,
                        pathname: pathname,
                        search: search,
                        hash: hash,
                    },
                };
            }
            catch (error) {
                return { error: error };
            }
        }
        else {
            return undefined;
        }
    };
    ReactEmbed.getDerivedStateFromError = function (error) {
        return { error: error };
    };
    ReactEmbed.prototype.render = function () {
        if (!IS_BROWSER)
            return null;
        var _a = this, props = _a.props, state = _a.state;
        if (state.error)
            return props.renderVoid(props, state, state.error);
        var result;
        try {
            result = props.router(props.blocks, state.url);
        }
        catch (error) {
            console.error('Could not route block:', error);
            return props.renderVoid(props, state, error);
        }
        if (!result || !result[0])
            return props.renderVoid(props, state);
        var _b = result, Block = _b[0], id = _b[1];
        return props.render(Block, id, props, state);
    };
    ReactEmbed.defaultProps = {
        width: typeof window === 'object' ? window.innerWidth : 0,
        isDark: false,
        blocks: defaultBlocks,
        router: defaultRouter,
        render: defaultRender,
        renderVoid: renderNull,
        renderWrap: renderWrap,
    };
    return ReactEmbed;
}(React.PureComponent));
export { ReactEmbed };
export default ReactEmbed;
