// Imports
import {NavLink, Link} from "react-router-dom";
import SocialLink from "../socials/SocialLink";

//Store
import { useDispatch, useSelector } from "react-redux";
import { menuActions } from "../../store/mainStore";
import { authActions } from "../../store/auth";
import {themeActions} from "../..//store/themeStore";

// CSS Styles
import CSS from "./MainNavigation.module.css";

function MainNavigation(props){
    const dispatch = useDispatch();
    const bIsAuthenticated = useSelector(state => state.auth.bIsAuthenticated);
    const bSocialsVisible = useSelector(state => state.menu.bSocialsVisible);
    const bThemeChangeVisible = useSelector(state => state.menu.bThemeChangeVisible);

    const menuClasses = props.menuOpen ? `${CSS.mobileNav} ${CSS.mobileNavOpen}` : `${CSS.mobileNav} ` ;
    const menuInnerClasses = props.menuOpen ? `${CSS.mobileInner} ${CSS.mobileInnerOpen}` : `${CSS.mobileInner} ` ;
    const menuAction = props.menuOpen ? closeMenuButtonHandler : openMenuButtonHandler ;
    const menuButtonClasses = props.menuOpen ? `${CSS.menuButton} ${CSS.closeMenuButton}` : `${CSS.menuButton} ${CSS.openMenuButton}` ;
    const svgRec1 = props.menuOpen ? `${CSS.rectangle1Close} ` : `${CSS.rectangle1} ` ;
    const svgRec2 = props.menuOpen ? `${CSS.rectangle2Close} ` : `${CSS.rectangle2} ` ;
    const svgRec3 = props.menuOpen ? `${CSS.rectangle3Close} ` : `${CSS.rectangle3} ` ;

    function openMenuButtonHandler(){
        dispatch(menuActions.openMenu());
    }
    function closeMenuButtonHandler(){
        dispatch(menuActions.closeMenu());
    }
    function logoutHandler(){
        dispatch(authActions.logout());
    }
    function menuButtonHandler(){
        dispatch(menuActions.closeMenu());
    }
    function themeChangeHandler(){
        dispatch(themeActions.changeTheme());
    }

    return(
    <>
        <nav className={CSS.nav}>
            <div className={menuClasses}>
                <div className={menuInnerClasses}>
                    <ul className={CSS.mobilePages}>
                        <li className={CSS.svgWrapper}>
                            <NavLink className={CSS.link} to="/" onClick={menuButtonHandler}>
                                <svg className={CSS.svg} height="64" width="288" xmlns="http://www.w3.org/2000/svg">
                                <rect className={CSS.shape} height="64" width="288" />
                                </svg>
                                Home
                            </NavLink>
                        </li>
                        <li className={CSS.svgWrapper}>
                            <NavLink className={CSS.link} to="/portfolio" onClick={menuButtonHandler}>
                                <svg className={CSS.svg} height="64" width="288" xmlns="http://www.w3.org/2000/svg">
                                <rect className={CSS.shape} height="64" width="288" />
                                </svg>
                                Portfolio
                            </NavLink>
                        </li>
                        <li className={CSS.svgWrapper}>
                            <NavLink className={CSS.link} to="/about-me" onClick={menuButtonHandler}>
                                <svg className={CSS.svg} height="64" width="288" xmlns="http://www.w3.org/2000/svg">
                                <rect className={CSS.shape} height="64" width="288" />
                                </svg>
                                About Me
                            </NavLink>
                        </li>
                        <li className={CSS.svgWrapper}>
                            <NavLink className={CSS.link} to="/contact" onClick={menuButtonHandler}>
                                <svg className={CSS.svg} height="64" width="288" xmlns="http://www.w3.org/2000/svg">
                                <rect className={CSS.shape} height="64" width="288" />
                                </svg>  
                                Contact
                            </NavLink>
                        </li>
                        <li className={CSS.svgWrapper}>
                            <NavLink className={CSS.link} to={{pathname: "https://www.BucciGames.com"}} target="_blank" rel="noreferrer" onClick={menuButtonHandler}>
                                <svg className={CSS.svg} height="64" width="288" xmlns="http://www.w3.org/2000/svg">
                                <rect className={CSS.shape} height="64" width="288" />
                                </svg>  
                                Game Blog
                            </NavLink>
                        </li>
                        {bIsAuthenticated && <>
                        <li className={CSS.svgWrapper}>
                            <NavLink className={CSS.link} to="/admin/AdminDashboard" onClick={menuButtonHandler}>
                                <svg className={CSS.svg} height="64" width="288" xmlns="http://www.w3.org/2000/svg">
                                <rect className={CSS.shape} height="64" width="288" />
                                </svg>  
                                Admin Dashboard
                            </NavLink>
                        </li>
                        <li className={CSS.svgWrapper}>
                            <NavLink className={CSS.link} to="#" onClick={menuButtonHandler}>
                                <svg className={CSS.svg} height="64" width="288" xmlns="http://www.w3.org/2000/svg">
                                <rect className={CSS.shape} height="64" width="288" />
                                </svg>  
                                Blog
                            </NavLink>
                        </li>                          
                        <li className={CSS.svgWrapper}>
                            <NavLink className={CSS.link} to="#" onClick={logoutHandler}>
                                <svg className={CSS.svg} height="64" width="288" xmlns="http://www.w3.org/2000/svg">
                                <rect className={CSS.shape} height="64" width="288" />
                                </svg>  
                                Logout
                            </NavLink>
                        </li>
                        </>}
                    </ul>
                    {bThemeChangeVisible&& 
                        <button onClick={themeChangeHandler}>Theme</button>
                    }
                    {bSocialsVisible && 
                    <ul className={CSS.socials}>
                        <li><SocialLink type="twitch"/></li>
                        <li><SocialLink type="twitter"/></li>
                        <li><SocialLink type="youtube"/></li>
                    </ul>
                    }
                </div>
            </div>
            <div className={CSS.mainNav}>
                <NavLink className={CSS.logo} to="/" onClick={menuButtonHandler}></NavLink>
                <button className={menuButtonClasses} onClick={menuAction}>
                    <svg viewBox="0 0 100 80" width="40" height="40">
                        <rect className={svgRec1} width="100" height="10"></rect>
                        <rect className={svgRec2} y="35" width="100" height="10"></rect>
                        <rect className={svgRec3} y="70" width="100" height="10"></rect>
                    </svg>
                </button>
            </div>
        </nav>
    </>

);}

export default MainNavigation;