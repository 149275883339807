// Imports
import {  useRouteMatch, useLocation } from "react-router-dom";
import Vid from "../../vids/BowlingHorizontal.webm"

// CSS Styles
import CSS from "./Banner.module.css";

function Banner(){
    const routeMatch = useRouteMatch();
    const bigBanner = routeMatch.url === "/" && routeMatch.isExact;
    
    //Url of page, routematch is of component
    const location = useLocation();
    let pageTitle = location.pathname;
    //If its a project page
    if(location.pathname.includes("0"))
    {
        pageTitle = "/portfolio"
    }
    const classes = bigBanner ? `${CSS.banner} ${CSS.big}` : `${CSS.banner}`
    const pageTitleClasses = bigBanner ? `${CSS.pageTitleHidden} ${CSS.pageTitle}` : `${CSS.pageTitle}`
    return(
    <div className={classes}> 
        <video autoPlay muted loop className={CSS.video} src={Vid} />
        <h1 className={pageTitleClasses}>{pageTitle}</h1>
    {bigBanner && 
        <div className={CSS.tickerWrapperV}>
            <ul className={CSS.newsTickerV}>
                <li>Animator</li>
                <li>Designer</li>
                <li>Programmer</li>
                <li>Animator</li>
                {/* Fourth reqired to look smooth */}
            </ul>
        </div>}
        <div className="shade"></div>
	</div>
);}

export default Banner;