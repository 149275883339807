const FIREBASE_DOMAIN = 'https://curtisbucciol-default-rtdb.asia-southeast1.firebasedatabase.app/';

export async function getFullPortfolio() {
    const response = await fetch(`${FIREBASE_DOMAIN}/portfolio.json`);
    const data = await response.json();
  
    if (!response.ok) {
      throw new Error(data.message || 'Could not fetch portfolio.');
    }
  
    const transformedPortfolio = [];
  
    for (const key in data) {
      const portfolioObject = {
        id: key,
        ...data[key],
      };
      transformedPortfolio.push(portfolioObject);
    }
    return transformedPortfolio;
}

export async function getProjectDetails(projectId) {
    const response = await fetch(`${FIREBASE_DOMAIN}/portfolio/${projectId}.json`);
    const data = await response.json();
  
    if (!response.ok) {
      throw new Error(data.message || 'Could not fetch portfolio Details.');
    }
  
    const loadedPorject = {
      id: projectId,
      ...data,
    };
    return loadedPorject;
}