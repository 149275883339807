// Imports

import Bio from "../components/aboutme/Bio";
import Skills from "../components/aboutme/Skills";
import ContactForm from "../components/form/ContactForm";

// CSS Styles

function AboutMe(){

   return(
    <>
        <Bio />
        {/* <Skills />*/}
    </>
);}

export default AboutMe;