// Imports
import {Link, Route, useParams, useRouteMatch} from "react-router-dom";
import { useEffect } from "react";
import Carousel from "../components/carousel/Carousel";
import useHttp from "../hooks/useHttp";
// CSS Styles
import CSS from "./PortfolioPiece.module.css";
//Data
import { getProjectDetails } from "../lib/api";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import { FULL_DUMMY_DATA } from "../data/FullDummyData";
import InstagramPost from "../components/UI/InstagramPost";
import Embed from 'react-embed';
import VideoPlayer from "../components/UI/VideoPlayer";


// import InstagramEmbed from 'react-instagram-embed';

function PortfolioPiece(){
    //Reset Scroll to top of page
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
    }, []);
 
    const {portfolioId} = useParams();
    //todo upload all to firebase 
    // const {sendRequest, status, data: PortfolioData, error} = useHttp(getProjectDetails, true);
    
    // useEffect(() => {    
        // sendRequest(portfolioId);
    // }, [sendRequest, portfolioId]);
    // },[]);

    // if(status === "pending")
    // {
    //     return (
    //     <>
    //         <Banner />
    //         <div className="centered">
    //             <LoadingSpinner />
    //         </div>
    //     </>
    //     );
    // }
    // if(error)
    // {
    //     return (
    //         <div className="centered focused">
    //             <p>Error fetching data {error}</p>
    //         </div>
    //     );
    // }
//                         AppID: "526764899154438",
        // <Embed url='https://www.youtube.com/watch?v=j1MupcMdKF4&' />

            // <InstagramEmbed 
            // url='https://instagram.com/p/CcmNwYHBdSg/'
            // clientAccessToken='526764899154438 | ed4470022fa6534ac6856dbc0251445a'
            // maxWidth={320}
            // hideCaption={false}
            // containerTagName='div'
            // protocol=''
            // injectScript
            // onLoading={(e) => {console.log("Loading")}}
            // onSuccess={(e) => {console.log("Success")}}
            // onAfterRender={() => {}}
            // onFailure={(e) => {console.log(e)}}
            // />
        // <InstagramPost />
    const project = FULL_DUMMY_DATA.find((project) => project.id === portfolioId);

    if (!project) {
        return (
    <>
        <div className="centered">
            <p className="secondary">No project found!</p>
        </div>
    </>
    );}
    
    const dateCompleted = (project.dateCompleted.getMonth().toString() + "/" + project.dateCompleted.getFullYear().toString())
    const programs = project.programs.toString();
    const category = project.categories.toString();
    const subtitle = project.subtitle.toString();
    const bHasImages = project.images;
    const bHasLink = project.link;
    const summary = project.summary && project.summary;

    return(
<>
    <div className={CSS.body}>
    <h1 className={CSS.projectName}>{project.projectName}</h1>
    <hr className="breaker"/>
        <div className={CSS.left}>
            <Link to="/portfolio" className="btn">Back</Link>
            {bHasImages && <div className={CSS.images}>
                <Carousel slides={project.images}/>
            </div>}
            {/* {bHasLink &&
                <Embed url={project.link} />
            } */}


            {/* <div className={CSS.buttons}>
                <Link href="#" className="btn">View Website</Link>
            </div> */}
        </div>
        <div className={CSS.description}>
            <h1 className={CSS.projectName}>{project.projectName}</h1>
            <hr />
            <ul>
                <li>{subtitle}</li>
                <li>{category}</li>
                <li>Finished : {dateCompleted}</li>
                {/* <li>Role: Designer</li> */}
                {/* <li>For: Client / Freelance / Personal</li> */}
                <li>Programs: {programs} </li>
            </ul>
            <h3>Summary</h3>
            <p className={CSS.summary}>{summary}</p>
            {bHasLink && 
                project.link.map((item) => (
                    <Link className="btn" to={{ pathname: item}} target="_blank" rel="noreferrer">Link</Link>
                ))
            }
            {/* <h3>Goals</h3>
            <p>
                    Short description of the Project Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pharetra posuere feugiat. Vestibulum purus ligula, malesuada sed erat sed, finibus condimentum est. Suspendisse bibendum, sapien eu suscipit porta, neque sapien ornare mi, eu mollis justo lacus sed risus. Proin posuere quam leo, id iaculis magna pellentesque at. Phasellus a consequat dui. Aenean vel neque a augue pharetra elementum. Nunc suscipit aliquet volutpat. Aliquam quis sagittis felis, ut viverra risus. Ut ut sapien risus. In tortor enim, consequat sit amet ultricies non, luctus ut nibh. Curabitur vel sollicitudin tellus.</p>
            <h3>Challenges</h3>
            <p>
                    Short description of the Project Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pharetra posuere feugiat. Vestibulum purus ligula, malesuada sed erat sed, finibus condimentum est. Suspendisse bibendum, sapien eu suscipit porta, neque sapien ornare mi, eu mollis justo lacus sed risus. Proin posuere quam leo, id iaculis magna pellentesque at. Phasellus a consequat dui. Aenean vel neque a augue pharetra elementum. Nunc suscipit aliquet volutpat. Aliquam quis sagittis felis, ut viverra risus. Ut ut sapien risus. In tortor enim, consequat sit amet ultricies non, luctus ut nibh. Curabitur vel sollicitudin tellus.</p>
            <h3>Conclusion</h3>
            <p>
                This game jam was a lot of fun and really tought me a lot about how much time I should be spending on certain parts of the game I'm making. It got me excited to apply these new approches to my current projects. I will definitely be entering next year.
            </p> */}
        </div>
        {/* <div>
            Comments
        </div> */}
    </div>
</>
);}

export default PortfolioPiece;