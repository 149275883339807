// Imports
import { menuActions } from "../../store/mainStore";
import { useDispatch, useSelector } from "react-redux";

// CSS Styles

function AdminDashboard(){
    const dispatch = useDispatch();
    const bSocialsVisible = useSelector(state => state.menu.bSocialsVisible);
    const bThemeChangeVisible = useSelector(state => state.menu.bThemeChangeVisible);

    function showThemeChanger(){
        dispatch(menuActions.toggleShowThemeChanger());
    }
    function showSocials(){
        dispatch(menuActions.toggleShowSocials());
    }

   return(
<>
    <h2 className="secondary">Admin Dashboard</h2>
    <button>Submit Portfolio Piece</button>
    <button>Submit Portfolio Piece</button>
    <button onClick={showThemeChanger}>{bThemeChangeVisible ? "Hide" : "Show"} Theme Changer</button>
    <button onClick={showSocials}>{bSocialsVisible ? "Hide" : "Show"} Social Links</button>
    <button > Enable Comments</button>

</>
);}

export default AdminDashboard;