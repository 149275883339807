// Imports
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
// CSS Styles
import CSS from './Login.module.css';

function Login(){
    const dispatch = useDispatch();

    function loginHandler(event){
        event.preventDefault();
        dispatch(authActions.login());
    }

   return(
<>
    <main className={CSS.auth}>
        <h2 className="secondary">Admin Login</h2>
        <section>
            <form onSubmit={loginHandler}>
            <div className={CSS.control}>
                <label htmlFor='email'>Email</label>
                <input type='email' id='email' />
            </div>
            <div className={CSS.control}>
                <label htmlFor='password'>Password</label>
                <input type='password' id='password' />
            </div>
            <button className="btn">Login</button>
            </form>
        </section>
    </main>
</>

);}

export default Login;