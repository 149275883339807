import {Route, Switch, Redirect} from "react-router-dom";
import {themeActions} from "./store/themeStore";
import Footer from "./components/layout/Footer";
import MainNavigation from "./components/layout/MainNavigation";
import AboutMe from "./pages/AboutMe";
import AdminDashboard from "./pages/admin/AdminDashboard";
import Home from "./pages/Home";
import NotFound404 from "./pages/NotFound404";
import Portfolio from "./pages/Portfolio";
import PortfolioPiece from "./pages/PortfolioPiece";
import SubmitPortfolioPiece from "./pages/admin/SubmitPortfolioPiece";
import {useSelector} from "react-redux";
import Contact from "./pages/Contact";
import Login from "./pages/admin/Login";
import Banner from "./components/layout/Banner";
import VideoPlayer from "./components/UI/VideoPlayer";

function App() {
    const bIsMenuOpen = useSelector(state => state.menu.bIsMenuOpen);
    const bIsAuthenticated = useSelector(state => state.auth.bIsAuthenticated);
    const bIsDarkTheme = useSelector(state => state.theme.bDarkTheme);


    const AppCSS = bIsDarkTheme ? "App AppDark": "App" ;
    const bgBlurClass = bIsMenuOpen ? "wrapperBlur" : "wrapper";

    return(
<div className={AppCSS}>
    <MainNavigation menuOpen={bIsMenuOpen} />
    <div className={bgBlurClass}>
    <Banner />
    {/* todo: <VideoPlayer /> */}
    <Switch>
        <Route path="/" exact>
            <Home />
        </Route>
        <Route path="/about-me" exact>
            <AboutMe />
        </Route>      
        <Route path="/portfolio" exact>
            <Portfolio />
        </Route>
        <Route path="/portfolio/:portfolioId">
            <PortfolioPiece />
        </Route>
        <Route path="/admin">
            {bIsAuthenticated && <AdminDashboard />}
            {!bIsAuthenticated && <Login />}
        </Route>
        <Route path="/submit">
            <SubmitPortfolioPiece />
        </Route>
        <Route path="/contact">
            <Contact />
        </Route>
        <Route path="*">
            <NotFound404 />
        </Route>
    </Switch>
    {/* <Footer /> */}
    </div>
</div>
);}

export default App;
