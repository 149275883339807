import {createSlice} from "@reduxjs/toolkit";

const initialAuthState = {
    bIsAuthenticated: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialAuthState,
    reducers: {
        login(state){
            state.bIsAuthenticated = true;
        },
        logout(state){
            state.bIsAuthenticated = false;
        },
    },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;