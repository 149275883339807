import { Link } from "react-router-dom";
import CSS from "./SocialLink.module.css"




function SocialLink(props) {
    
    let Class = CSS.socialMediaIcon;
    if(props.dark)
    {
        Class = CSS.socialMediaIconDark;
    }
    const githubIcon = (
        // <svg className={CSS.socialMediaIcon} version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" >
        //     <path d="M 0 0 L 512 0 L 512 512 L 347.033 512 C 346.672 506.974 344.589 499 335 499 C 322 499 319 493 319 487 L 320 417 
        //         C 320 393 312 377 302 369 C 359 363 419 341 419 243 C 419 215 409 192 393 174 C 396 168 404 142 390 107 C 390 107 369 100 
        //         320 133 C 278 121 234 121 192 133 C 143 100 122 107 122 107 C 108 142 116 168 119 174 C 103 192 93 215 93 243 C 93 341 152 
        //         363 209 369 C 202 376 195 387 193 404 C 178 410 141 421 119 382 C 119 382 105 358 79 356 C 79 356 54 356 78 372 C 78 372 
        //         94 379 106 409 C 106 409 121 453 192 437 L 193 487 C 193 493 190 499 177 499 C 167.411 499 165.328 506.974 164.967 512 L 
        //         0 512 Z" />
        // </svg>
        <svg className={`${Class} ${CSS.github}`} viewBox="0 0 512 512" width="512" height="512" xmlns="http://www.w3.org/2000/svg">
            <path d="M 347.033 512 C 346.672 506.974 344.589 499 335 499 C 322 499 319 493 319 487 L 320 417 C 320 393 312 377 302 369 
            C 359 363 419 341 419 243 C 419 215 409 192 393 174 C 396 168 404 142 390 107 C 390 107 369 100 320 133 C 278 121 234 121 
            192 133 C 143 100 122 107 122 107 C 108 142 116 168 119 174 C 103 192 93 215 93 243 C 93 341 152 363 209 369 C 202 376 195 
            387 193 404 C 178 410 141 421 119 382 C 119 382 105 358 79 356 C 79 356 54 356 78 372 C 78 372 94 379 106 409 C 106 409 
            121 453 192 437 L 193 487 C 193 493 190 499 177 499 C 167.411 499 165.328 506.974 164.967 512" />
        </svg>
    
    );
    const facebookIcon = (
        // <svg className={CSS.socialMediaIcon} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 455 455">
        //     <path  d="M0,0v455h455V0H0z M301.004,125.217H265.44  
        //         c-7.044,0-14.153,7.28-14.153,12.696v36.264h49.647c-1.999,27.807-6.103,53.235-6.103,53.235h-43.798V385h-65.266V227.395h-31.771  
        //         v-53.029h31.771v-43.356c0-7.928-1.606-61.009,66.872-61.009h48.366V125.217z"/>
        // </svg>
        <svg className={`${Class} ${CSS.facebook}`} viewBox="0 0 512 512" width="512" height="512" xmlns="http://www.w3.org/2000/svg">
            <path d="M 371.999 94.583 L 315.874 94.583 C 304.757 94.583 293.54 106.072 293.54 114.619 L 293.54 171.849 L 371.889 171.849 C 
            368.733 215.732 362.256 255.86 362.256 255.86 L 293.138 255.86 L 293.138 504.556 L 190.139 504.556 L 190.139 255.835 L 140 255.835 
            L 140 172.147 L 190.139 172.147 L 190.139 103.725 C 190.139 91.214 187.603 7.445 295.672 7.445 L 372 7.445 L 372 94.583 L 371.999 94.583 Z"/>
        </svg>
    
    );
    
    const youtubeIcon = (
        // <svg className={CSS.socialMediaIcon} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 455 455" >
        //     <path 
        //         d="M0,0v455h455V0H0z M379.493,301.961
        //         c-3.861,16.75-17.563,29.118-34.057,30.96c-39.044,4.353-78.575,4.382-117.952,4.353c-39.361,0.029-78.892,0-117.948-4.353
        //         c-16.498-1.841-30.199-14.21-34.04-30.96C70,278.114,70,252.061,70,227.499c0-24.562,0.065-50.603,5.556-74.45
        //         c3.841-16.766,17.547-29.118,34.024-30.959c39.06-4.365,78.608-4.382,117.969-4.365c39.357-0.016,78.892,0,117.948,4.365
        //         c16.498,1.841,30.199,14.193,34.057,30.959c5.495,23.847,5.446,49.888,5.446,74.45C385,252.061,384.984,278.114,379.493,301.961z"
        //         />
        //     <path 
        //         d="M186.855,276.399c33.467-17.355,66.638-34.565,100.138-51.932
        //         c-33.597-17.53-66.764-34.821-100.138-52.237V276.399z"/>
        // </svg>
        <svg className={`${Class} ${CSS.youtube}`} viewBox="0 0 512 512" width="512" height="512" xmlns="http://www.w3.org/2000/svg">
            <path d="M 481.805 366.997 C 479.005 379.302 472.645 389.777 463.879 397.732 C 455.114 405.682 444.269 410.829 432.173 412.14 
            C 373.876 418.643 314.852 418.682 255.974 418.639 C 197.124 418.682 138.1 418.643 79.785 412.14 C 67.686 410.829 56.84 405.682 
            48.08 397.732 C 39.316 389.778 32.962 379.302 30.178 366.997 C 21.98 331.511 22 292.723 22 255.998 C 22 219.274 22.079 180.503 
            30.27 145.013 C 33.052 132.698 39.408 122.223 48.169 114.273 C 56.926 106.326 67.769 101.185 79.851 99.876 C 138.172 93.354 
            197.222 93.332 256.073 93.357 C 314.919 93.333 373.949 93.354 432.265 99.876 C 444.362 101.185 455.209 106.326 463.974 114.273 
            C 472.74 122.224 479.099 132.7 481.896 145.016 C 490.093 180.502 490 219.271 490 255.998 C 490 292.723 489.996 331.508 481.805 
            366.997 Z M 196.723 326.654 C 245.95 301.125 291.624 277.427 341.714 251.46 C 292.35 225.705 245.886 201.48 196.723 175.827 Z"/>
        </svg>
    
    );
    
    const twitterIcon = ( 
        // <svg className={CSS.socialMediaIcon}  xmlns="http://www.w3.org/2000/svg" version="1.1"  viewBox="0 0 455 455" >
        //     <path 
        //     d="M0,0v455h455V0H0z M352.751,163.259c0.123,2.773,0.186,5.561,0.186,8.36  c0,85.403-65.002,183.876-183.873,183.876c-36.496,
        //     0-70.466-10.697-99.065-29.037c5.056,0.601,10.199,0.907,15.417,0.907  
        //     c30.278,0,58.143-10.331,80.262-27.668c-28.28-0.519-52.148-19.204-60.373-44.88c3.948,0.757,7.997,1.163,12.161,1.163  
        //     c5.894,0,11.604-0.794,17.027-2.268c-29.563-5.939-51.841-32.057-51.841-63.368c0-0.273,0-0.544,0.006-0.814  
        //     c8.712,4.84,18.676,7.748,29.271,8.084c-17.342-11.589-28.748-31.371-28.748-53.79c0-11.845,3.187-22.945,8.751-32.492  
        //     c31.873,39.101,79.493,64.828,133.203,67.526c-1.103-4.732-1.677-9.665-1.677-14.729c0-35.688,28.938-64.623,64.626-64.623  
        //     c18.589,0,35.385,7.847,47.173,20.406c14.719-2.895,28.551-8.276,41.038-15.681c-4.824,15.092-15.071,27.754-28.415,35.754  
        //     c13.074-1.563,25.528-5.038,37.118-10.178C376.336,142.766,365.38,154.149,352.751,163.259z"/>
        // </svg>
        <svg className={`${Class} ${CSS.twitter}`} viewBox="0 0 512 512" width="512" height="512" xmlns="http://www.w3.org/2000/svg">
        <path d="M 451.715 155.618 C 451.908 159.952 452.005 164.309 452.005 168.681 C 452.005 302.129 350.436 456 164.692 456 C 107.664 
        456 54.583 439.285 9.896 410.627 C 17.796 411.567 25.831 412.044 33.987 412.044 C 81.298 412.044 124.838 395.902 159.402 368.812 
        C 115.211 368.001 77.916 338.804 65.063 298.683 C 71.233 299.867 77.561 300.5 84.066 300.5 C 93.277 300.5 102.199 299.261 110.672 
        296.958 C 64.478 287.675 29.668 246.866 29.668 197.939 C 29.668 197.516 29.668 197.09 29.677 196.669 C 43.291 204.231 58.86 208.776 
        75.415 209.3 C 48.316 191.191 30.495 160.28 30.495 125.25 C 30.495 106.74 35.474 89.397 44.167 74.478 C 93.971 135.577 168.379 
        175.778 252.307 179.992 C 250.584 172.599 249.687 164.89 249.687 156.978 C 249.687 101.213 294.904 56 350.668 56 C 379.715 56 405.959 
        68.261 424.379 87.887 C 447.378 83.362 468.993 74.954 488.504 63.383 C 480.966 86.965 464.954 106.75 444.104 119.252 C 464.532 
        116.809 483.992 111.38 502.103 103.347 C 488.567 123.596 471.449 141.384 451.715 155.618 Z"/>
      </svg>
    );
    
    const twitchIcon = (
        // <svg className={CSS.socialMediaIcon} viewBox="0 0 455 455" xmlns="http://www.w3.org/2000/svg">
        //     <path d="M 0.027 -0.111 L 455.027 -0.111 L 455.027 454.889 L 0.027 454.889 Z M 216.464 186.794 L 248.64 186.794 L 248.64 
        //     90.36 L 216.483 90.36 Z M 304.892 186.794 L 337.03 186.794 L 337.03 90.36 L 304.892 90.36 Z M 112.024 1.97 L 31.66 82.335 
        //     L 31.66 371.619 L 128.093 371.619 L 128.093 451.983 L 208.458 371.619 L 272.735 371.619 L 417.395 226.976 L 417.395 1.97 Z 
        //     M 385.238 210.907 L 320.961 275.185 L 256.666 275.185 L 200.414 331.436 L 200.414 275.185 L 128.093 275.185 L 128.093 34.109 
        //     L 385.238 34.109 Z" />
        // </svg>
        <svg className={`${Class} ${CSS.twitch}`} viewBox="0 0 512 512" width="512" height="512" xmlns="http://www.w3.org/2000/svg">
        <path d="M 247.937 215.818 L 280.113 215.818 L 280.113 119.384 L 247.956 119.384 L 247.937 215.818 Z M 336.365 215.818 L 368.503 215.818 
        L 368.503 119.384 L 336.365 119.384 L 336.365 215.818 Z M 143.497 30.994 L 63.133 111.359 L 63.133 400.643 L 159.566 400.643 L 159.566 
        481.007 L 239.931 400.643 L 304.208 400.643 L 448.868 256 L 448.868 30.994 L 143.497 30.994 Z M 416.711 239.931 L 352.434 304.209 L 
        288.139 304.209 L 231.887 360.46 L 231.887 304.209 L 159.566 304.209 L 159.566 63.133 L 416.711 63.133 L 416.711 239.931 Z"/>
      </svg>
    );
    
    const linkedinIcon = (
        // <svg className={CSS.socialMediaIcon} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 455 455" >
        //     <path  d="M246.4,204.35v-0.665c-0.136,0.223-0.324,0.446-0.442,0.665H246.4z"/>
        //     <path  d="M0,0v455h455V0H0z M141.522,378.002H74.016V174.906h67.506V378.002z
        //         M107.769,147.186h-0.446C84.678,147.186,70,131.585,70,112.085c0-19.928,15.107-35.087,38.211-35.087
        //         c23.109,0,37.31,15.159,37.752,35.087C145.963,131.585,131.32,147.186,107.769,147.186z M385,378.002h-67.524V269.345
        //         c0-27.291-9.756-45.92-34.195-45.92c-18.664,0-29.755,12.543-34.641,24.693c-1.776,4.34-2.24,10.373-2.24,16.459v113.426h-67.537
        //         c0,0,0.905-184.043,0-203.096H246.4v28.779c8.973-13.807,24.986-33.547,60.856-33.547c44.437,0,77.744,29.02,77.744,91.398V378.002
        //         z"/>
        // </svg>
        <svg className={`${Class} ${CSS.linkedin}`} viewBox="0 0 512 512" width="512" height="512" xmlns="http://www.w3.org/2000/svg">
            <path d="M246.4,204.35v-0.665c-0.136,0.223-0.324,0.446-0.442,0.665H246.4z"/>
            <path d="M 157.739 428.002 L 80.59 428.002 L 80.59 195.892 L 157.739 195.892 L 157.739 428.002 Z M 119.165 164.212 L 118.655 164.212 
            C 92.775 164.212 76 146.382 76 124.096 C 76 101.322 93.265 83.997 119.67 83.997 C 146.08 83.997 162.31 101.322 162.815 124.096 C 162.815 
            146.382 146.08 164.212 119.165 164.212 Z M 436 428.002 L 358.83 428.002 L 358.83 303.822 C 358.83 272.632 347.68 251.342 319.75 251.342 
            C 298.419 251.342 285.744 265.677 280.16 279.563 C 278.13 284.523 277.6 291.418 277.6 298.373 L 277.6 428.003 L 200.415 428.003 C 200.415 
            428.003 201.449 217.668 200.415 195.893 L 277.6 195.893 L 277.6 228.783 C 287.855 213.004 306.155 190.444 347.15 190.444 C 397.935 
            190.444 436 223.61 436 294.899 L 436 428.002 Z"/>
        </svg>
    );
    
    const soundcloudIcon = (
        // <svg className={CSS.socialMediaIcon} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 455 455">
        //     <path 
        //         d="M0,0v455h455V0H0z M74.827,276.849c-0.105,0.722-0.622,1.224-1.26,1.224
        //         c-0.657,0-1.176-0.506-1.263-1.231L70,259.647l2.303-17.494c0.087-0.724,0.606-1.232,1.263-1.232c0.639,0,1.157,0.504,1.26,1.226
        //         l2.728,17.5L74.827,276.849z M86.52,287.317c-0.106,0.747-0.643,1.27-1.307,1.27c-0.669,0-1.22-0.536-1.31-1.277l-3.097-27.663
        //         c0,0,3.097-28.284,3.097-28.287c0.09-0.738,0.641-1.273,1.31-1.273c0.664,0,1.201,0.521,1.308,1.272l3.524,28.289L86.52,287.317z
        //         M99.164,291.995c-0.097,0.893-0.76,1.542-1.576,1.542c-0.832,0-1.496-0.649-1.58-1.548l-2.939-32.338
        //         c0,0,2.939-33.561,2.939-33.564c0.083-0.896,0.747-1.546,1.58-1.546c0.817,0,1.479,0.648,1.578,1.545l3.339,33.565L99.164,291.995z
        //         M111.91,293.021c-0.088,1.031-0.882,1.812-1.845,1.812c-0.976,0-1.772-0.781-1.85-1.812l-2.779-33.364l2.779-34.487
        //         c0.078-1.035,0.873-1.816,1.85-1.816c0.964,0,1.758,0.779,1.845,1.81l3.157,34.493L111.91,293.021z M124.757,293.297
        //         c-0.083,1.187-0.992,2.086-2.116,2.086c-1.135,0-2.045-0.899-2.115-2.084l-2.623-33.638l2.623-31.99
        //         c0.071-1.191,0.98-2.089,2.115-2.089c1.125,0,2.035,0.897,2.116,2.076l2.972,32.003L124.757,293.297z M137.705,293.301
        //         c-0.075,1.32-1.122,2.359-2.384,2.359c-1.272,0-2.321-1.039-2.385-2.352l-2.463-33.635c0,0,2.463-52.047,2.463-52.048
        //         c0.064-1.323,1.113-2.361,2.385-2.361c1.262,0,2.309,1.035,2.384,2.358l2.786,52.05
        //         C140.491,259.673,137.705,293.311,137.705,293.301z M150.558,293.268c-0.068,1.475-1.234,2.63-2.652,2.63
        //         c-1.429,0-2.594-1.154-2.654-2.617l-2.305-33.426c0,0,2.302-63.947,2.302-63.951c0.062-1.476,1.228-2.63,2.657-2.63
        //         c1.419,0,2.584,1.155,2.652,2.63l2.604,63.951C153.162,259.855,150.556,293.287,150.558,293.268z M163.905,292.755
        //         c-0.065,1.628-1.348,2.898-2.923,2.898c-1.584,0-2.868-1.274-2.923-2.885l-2.145-33.082c0,0,2.144-69.286,2.144-69.288
        //         c0.057-1.627,1.34-2.903,2.924-2.903c1.575,0,2.858,1.274,2.923,2.902l2.419,69.289
        //         C166.324,259.687,163.904,292.775,163.905,292.755z M177.156,292.553c-0.058,1.775-1.457,3.167-3.19,3.167
        //         c-1.739,0-3.141-1.392-3.191-3.152l-1.987-32.877l1.985-71.621c0.051-1.78,1.453-3.174,3.192-3.174c1.734,0,3.134,1.393,3.191,3.172
        //         l2.237,71.626L177.156,292.553z M190.51,292.243c-0.052,1.929-1.57,3.444-3.459,3.444c-1.897,0-3.417-1.516-3.46-3.428
        //         l-1.828-32.562l1.826-69.795c0.044-1.93,1.565-3.442,3.462-3.442c1.889,0,3.407,1.51,3.459,3.438l2.053,69.799
        //         C192.563,259.697,190.51,292.266,190.51,292.243z M203.966,292.034c-0.045,2.084-1.683,3.712-3.728,3.712
        //         c-2.051,0-3.69-1.628-3.728-3.689l-1.668-32.357l1.667-67.246c0.04-2.083,1.679-3.715,3.73-3.715c2.045,0,3.683,1.631,3.728,3.708
        //         l1.868,67.257C205.834,259.703,203.966,292.061,203.966,292.034z M217.69,288.655l-0.167,3.179
        //         c-0.021,1.096-0.478,2.091-1.201,2.811c-0.724,0.721-1.714,1.168-2.798,1.168c-1.22,0-2.322-0.565-3.058-1.447
        //         c-0.545-0.652-0.885-1.479-0.931-2.377c-0.002-0.045-0.008-0.09-0.009-0.136c0,0-1.509-32.121-1.509-32.169l1.495-79.245
        //         l0.014-0.755c0.022-1.396,0.756-2.625,1.844-3.337c0.624-0.408,1.363-0.648,2.153-0.648c0.813,0,1.573,0.251,2.209,0.682
        //         c1.057,0.716,1.766,1.928,1.79,3.3l1.684,80.028L217.69,288.655z M231.019,291.359c-0.032,2.346-1.946,4.255-4.263,4.255
        //         c-2.325,0-4.238-1.909-4.269-4.221l-0.862-15.622l-0.888-16.053l1.74-86.842l0.009-0.439c0.018-1.319,0.631-2.501,1.577-3.282
        //         c0.737-0.608,1.675-0.974,2.692-0.974c0.791,0,1.532,0.226,2.17,0.613c1.231,0.745,2.071,2.095,2.093,3.636
        //         c0,0,1.899,87.287,1.899,87.288C232.918,259.717,231.019,291.396,231.019,291.359z M346.258,295.703c0,0-107.299,0.01-107.398,0
        //         c-2.315-0.232-4.154-2.074-4.185-4.446c0,0,0-122.968,0-122.97c0.028-2.261,0.804-3.422,3.729-4.556
        //         c7.526-2.911,16.045-4.63,24.786-4.63c35.718,0,64.999,27.392,68.082,62.308c4.612-1.932,9.678-3.008,14.986-3.008
        //         c21.397,0,38.742,17.347,38.742,38.747C385,278.55,367.655,295.703,346.258,295.703z"/>
        // </svg>
        <svg className={`${Class} ${CSS.soundcloud}`} viewBox="0 0 512 512" width="512" height="512" xmlns="http://www.w3.org/2000/svg">
            <path d="M 32.08 328.379 C 31.925 329.438 31.168 330.173 30.232 330.173 C 29.268 330.173 28.506 329.432 28.38 328.368 L 25 303.15 
            L 28.379 277.491 C 28.505 276.43 29.267 275.685 30.23 275.685 C 31.168 275.685 31.928 276.424 32.078 277.482 L 36.08 303.15 L 32.08 
            328.379 Z M 49.23 343.733 C 49.074 344.828 48.287 345.595 47.312 345.595 C 46.331 345.595 45.523 344.809 45.391 343.722 L 40.849 
            303.15 C 40.849 303.15 45.391 261.667 45.391 261.662 C 45.523 260.58 46.331 259.795 47.312 259.795 C 48.287 259.795 49.074 260.558 
            49.231 261.661 L 54.399 303.151 L 49.23 343.733 Z M 67.773 350.593 C 67.632 351.903 66.659 352.854 65.462 352.854 C 64.242 352.854 
            63.268 351.903 63.145 350.584 L 58.835 303.156 C 58.835 303.156 63.145 253.932 63.145 253.928 C 63.267 252.614 64.24 251.661 65.462 
            251.661 C 66.661 251.661 67.632 252.611 67.776 253.926 L 72.674 303.156 L 67.773 350.593 Z M 86.469 352.098 C 86.338 353.611 85.175 
            354.755 83.762 354.755 C 82.331 354.755 81.163 353.611 81.049 352.098 L 76.974 303.164 L 81.049 252.582 C 81.163 251.066 82.329 249.919 
            83.762 249.919 C 85.176 249.919 86.341 251.062 86.469 252.574 L 91.098 303.164 L 86.469 352.098 Z M 105.31 352.502 C 105.188 354.244 
            103.855 355.562 102.207 355.562 C 100.543 355.562 99.207 354.244 99.105 352.506 L 95.258 303.17 L 99.105 256.251 C 99.209 254.505 
            100.543 253.188 102.207 253.188 C 103.856 253.188 105.191 254.503 105.31 256.232 L 109.669 303.17 L 105.31 352.502 Z M 124.3 352.508 C 
            124.19 354.445 122.655 355.97 120.805 355.97 C 118.939 355.97 117.4 354.445 117.306 352.519 L 113.693 303.188 C 113.693 303.188 117.306 
            226.853 117.306 226.85 C 117.4 224.91 118.939 223.388 120.805 223.388 C 122.655 223.388 124.19 224.905 124.3 226.847 L 128.386 303.187 
            C 128.386 303.188 124.3 352.524 124.3 352.508 Z M 143.151 352.46 C 143.053 354.623 141.341 356.318 139.262 356.318 C 137.166 356.318 
            135.458 354.625 135.369 352.48 L 131.989 303.454 C 131.989 303.454 135.365 209.665 135.365 209.659 C 135.456 207.495 137.166 205.803 
            139.262 205.803 C 141.343 205.803 143.053 207.497 143.151 209.659 L 146.97 303.454 C 146.97 303.454 143.149 352.488 143.151 352.46 Z 
            M 162.727 351.709 C 162.632 354.096 160.75 355.959 158.44 355.959 C 156.117 355.959 154.233 354.09 154.153 351.728 L 151.006 303.207 
            C 151.006 303.207 154.152 201.588 154.152 201.585 C 154.234 199.198 156.117 197.327 158.44 197.327 C 160.75 197.327 162.632 199.195 
            162.727 201.583 L 166.275 303.207 C 166.275 303.208 162.726 351.738 162.727 351.709 Z M 182.163 351.411 C 182.078 354.015 180.025 
            356.056 177.484 356.056 C 174.932 356.056 172.877 354.015 172.803 351.434 L 169.889 303.214 L 172.8 198.169 C 172.876 195.56 174.932 
            193.515 177.483 193.515 C 180.025 193.515 182.079 195.559 182.163 198.167 L 185.443 303.219 L 182.163 351.411 Z M 201.747 350.956 C 
            201.672 353.786 199.445 356.008 196.674 356.008 C 193.892 356.008 191.662 353.784 191.6 350.98 L 188.919 303.223 L 191.597 200.856 C 
            191.661 198.026 193.892 195.808 196.674 195.808 C 199.445 195.808 201.672 198.023 201.747 200.85 L 204.759 303.223 C 204.759 303.223 
            201.747 350.991 201.747 350.956 Z M 221.484 350.651 C 221.418 353.707 219.015 356.094 216.016 356.094 C 213.008 356.094 210.603 353.707 
            210.547 350.684 L 208.102 303.228 L 210.546 204.6 C 210.604 201.546 213.009 199.151 216.018 199.151 C 219.016 199.151 221.419 201.543 
            221.485 204.589 L 224.225 303.233 C 224.224 303.232 221.484 350.69 221.484 350.651 Z M 241.612 345.694 L 241.368 350.357 C 241.337 
            351.964 240.666 353.424 239.606 354.481 C 238.544 355.537 237.092 356.192 235.501 356.192 C 233.713 356.192 232.097 355.364 231.016 
            354.071 C 230.218 353.115 229.719 351.902 229.652 350.584 C 229.648 350.518 229.64 350.452 229.638 350.385 C 229.638 350.385 227.425 
            303.274 227.425 303.205 L 229.617 186.977 L 229.638 185.871 C 229.671 183.823 230.748 182.021 232.342 180.976 C 233.258 180.378 
            234.342 180.026 235.5 180.026 C 236.693 180.026 237.807 180.394 238.74 181.026 C 240.291 182.076 241.331 183.853 241.366 185.866 L 
            243.835 303.241 L 241.612 345.694 Z M 261.161 349.66 C 261.114 353.102 258.308 355.901 254.908 355.901 C 251.5 355.901 248.693 353.102 
            248.648 349.71 L 247.384 326.799 L 246.081 303.254 L 248.633 175.884 L 248.646 175.242 C 248.673 173.307 249.572 171.574 250.959 170.428 
            C 252.041 169.536 253.416 168.999 254.907 168.999 C 256.068 168.999 257.155 169.33 258.091 169.899 C 259.896 170.991 261.127 172.971 
            261.16 175.232 C 261.16 175.232 263.945 303.253 263.945 303.254 C 263.946 303.253 261.161 349.715 261.161 349.66 Z M 430.179 356.031 
            C 430.179 356.031 272.807 356.045 272.662 356.031 C 269.265 355.691 266.569 352.99 266.523 349.511 C 266.523 349.511 266.523 169.158 
            266.523 169.155 C 266.564 165.839 267.703 164.137 271.992 162.472 C 283.032 158.204 295.525 155.682 308.346 155.682 C 360.732 155.682 
            403.677 195.857 408.199 247.067 C 414.964 244.233 422.393 242.655 430.179 242.655 C 461.56 242.655 487 268.098 487 299.485 C 487 330.874 
            461.56 356.031 430.179 356.031 Z"/>
        </svg>
    );
    
    const instagramIcon = (
        // <svg className={CSS.socialMediaIcon} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 455 455">
        //     <path d="M336.351,106.363h-35.83c-6.986,0.051-12.346,5.157-12.396,12.104
        //         c-0.051,12.155-0.051,24.259,0,36.414c0.051,6.897,5.411,12.066,12.307,12.066h35.817c7.189,0,12.447-5.22,12.447-12.409
        //         c0.051-11.914,0.051-23.865,0-35.767C348.696,111.622,343.438,106.415,336.351,106.363z"/>
        //     <path d="M227.591,288.104c33.493-0.051,60.534-27.143,60.534-60.635
        //         c-0.051-33.493-27.142-60.521-60.635-60.521c-33.378,0-60.47,27.129-60.521,60.47C166.918,260.961,194.06,288.104,227.591,288.104z
        //         "/>
        //     <path  d="M318.239,261.698c-7.24,19.102-19.344,34.433-36.173,45.952
        //         c-30.355,20.729-70.046,22.469-101.951,4.458c-16.131-9.132-28.781-21.782-37.608-38.104
        //         c-12.104-22.329-14.886-45.939-8.777-70.592h-27.345v132.51c0,7.595,5.169,12.752,12.752,12.752c4.864,0.051,9.678,0,14.543,0
        //         H335.55c8.192,0,13.146-5.004,13.146-13.197V203.361h-27.383C326.33,223.2,325.427,242.646,318.239,261.698z"/>
        //     <path  d="M0,0v455h455V0H0z M384.97,105.919V349.17
        //         c0,17.909-13.844,33.493-31.651,35.576c-1.397,0.152-2.781,0.254-4.166,0.254H105.89c-17.604,0-33.087-13.552-35.423-30.966
        //         c-0.292-2.032-0.445-4.115-0.445-6.147V106.96c0-18.162,12.155-32.947,30.012-36.363c2.134-0.406,4.37-0.546,6.554-0.546
        //         C187.253,70,267.879,70,348.505,70c18.9,0,34.433,14.035,36.363,32.795C385.021,103.836,384.97,104.877,384.97,105.919z"/>
        // </svg>
        <svg className={`${Class} ${CSS.instagram}`} viewBox="0 0 512 512" width="512" height="512" xmlns="http://www.w3.org/2000/svg">
            <path d="M 455.99 101.59 L 455.99 410.523 C 455.99 433.267 438.408 453.06 415.792 455.705 C 414.018 455.898 412.261 456.028 410.501 
            456.028 L 101.553 456.028 C 79.196 456.028 59.532 438.816 56.565 416.701 C 56.194 414.119 56 411.474 56 408.893 L 56 102.912 C 56 79.846 
            71.437 61.069 94.115 56.73 C 96.826 56.215 99.666 56.037 102.439 56.037 C 204.885 55.972 307.282 55.972 409.678 55.972 C 433.682 55.972 
            453.408 73.797 455.86 97.622 C 456.054 98.944 455.99 100.267 455.99 101.59 Z M 394.243 102.153 L 348.738 102.153 C 339.866 102.219 333.059 
            108.703 332.994 117.526 C 332.93 132.963 332.93 148.335 332.994 163.772 C 333.06 172.531 339.867 179.097 348.624 179.097 L 394.113 179.097 
            C 403.243 179.097 409.921 172.467 409.921 163.336 C 409.986 148.206 409.986 133.028 409.921 117.912 C 409.921 108.833 403.243 102.22 394.243 
            102.153 Z M 256.115 332.968 C 298.653 332.903 332.994 298.496 332.994 255.961 C 332.93 213.424 298.524 179.098 255.987 179.098 C 213.597 
            179.098 179.189 213.552 179.125 255.896 C 179.06 298.496 213.531 332.968 256.115 332.968 Z M 371.24 299.432 C 362.045 323.692 346.673 
            343.162 325.299 357.791 C 286.748 384.118 236.34 386.328 195.821 363.454 C 175.333 351.856 159.268 335.789 148.057 315.061 C 132.685 286.703 
            129.152 256.718 136.91 225.408 L 102.181 225.408 L 102.181 393.697 C 102.181 403.343 108.747 409.893 118.377 409.893 C 124.554 409.958 130.669 
            409.893 136.847 409.893 L 393.225 409.893 C 403.629 409.893 409.921 403.538 409.921 393.133 L 409.921 225.342 L 375.144 225.342 C 381.516 250.538 
            380.369 275.235 371.24 299.432 Z" />
        </svg>
    );

    let size = props.size
    let URL = "";
    let icon;

    switch (props.type) {
        case "twitter":
            icon = twitterIcon;
            URL = "https://twitter.com/CurtisBucci";
            break;
        case "twitch":
            icon = twitchIcon;
            URL = "https://www.twitch.tv/curtbucci/";
            break;
        case "facebook":
            icon = facebookIcon;
            URL = "https://www.facebook.com/Curtisbucci";
            break;
        case "github":
            icon = githubIcon;
            URL = "https://github.com/johnnymcoy";
            break;
        case "linkedin":
            icon = linkedinIcon;
            URL = "https://www.linkedin.com/in/curtis-bucciol/";
            break;
        case "soundcloud":
            icon = soundcloudIcon;
            URL = "https://soundcloud.com/curtis-bucci";
            break;
        case "youtube":
            icon = youtubeIcon;
            URL = "https://www.youtube.com/channel/UCJWORuER75XEbw3Z3tHl50g";
            break;
        case "instagram":
            icon = instagramIcon;
            URL = "https://www.instagram.com/curtisbucci/";
            break;
        default:
            icon = twitterIcon;
            URL = "https://twitter.com/CurtisBucci";
            break;
    }
  return (
    <Link className={CSS.link} title={props.type} to={{ pathname: URL}} target="_blank" rel="noreferrer">
        {icon}
    </Link>
);}

export default SocialLink;
