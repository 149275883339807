// CSS Styles
import CSS from './Carousel.module.css';
import { useState } from 'react';
import {FaArrowAltCircleRight, FaArrowAltCircleLeft, FaExpandArrowsAlt} from "react-icons/fa";

function Carousel(props){
    const [currentSlide, setCurrentSlide] = useState(0);
    const [fullscreen, setFullscreen] = useState(false);
    const CarouselCSS = fullscreen ? CSS.CarouselFull : CSS.Carousel;
    //default vaule incase props.slides is empty
    const slides = props.slides ? props.slides : [{images: "", alt: ""}];
    const length = slides.length; 
    const bIsSingle = length === 1;
    if(!props.slides)
    {
        return <p>No photos</p>
    };

    function nextSlide(){
        setCurrentSlide(currentSlide => currentSlide === length - 1 ? 0 : currentSlide + 1);
    };
    function previousSlide() {
        setCurrentSlide(currentSlide => currentSlide === 0 ? length - 1 : currentSlide - 1);
    };
    function thumbnailClick(index){
        setCurrentSlide(parseFloat(index.target.id));
    };
    function fullscreenHandler(){
        setFullscreen(prevState => !prevState);
    };
    // Exit fullscreen from Esc key 
    window.addEventListener("keydown", keypress);
    function keypress(e){
        if(e.key === "Escape")
        {
            exitFullscreen();
        };
    };
    function exitFullscreen(){
        setFullscreen(false);
    };

   return(
<div className={CarouselCSS}>
    <div className={CSS.backdrop} onClick={exitFullscreen}></div>
    {!bIsSingle && 
    <>
        <FaArrowAltCircleLeft className={CSS.leftArrow} onClick={previousSlide} />
        <FaArrowAltCircleRight className={CSS.rightArrow} onClick={nextSlide} /> 
    </>}
    <FaExpandArrowsAlt className={CSS.expand} onClick={fullscreenHandler} />
    {slides.map((slide, index) =>{
        return ( 
            <div className={index === currentSlide ? CSS.slideActive : CSS.slide} key={index}>
                {index === currentSlide && (
                <img src={slide.image} alt={slide.alt} className={CSS.image} onClick={nextSlide}/>)}
            </div>
        )})}
    {!bIsSingle && 
    <div className={CSS.thumbnails}>
        {slides.map((slide, index) => {
            return (
                <img className={index === currentSlide ? CSS.thumbnailActive : CSS.thumbnail} src={slide.image} alt={slide.alt} onClick={thumbnailClick} key={index} id={index}/>
        )})}
    </div>}
</div>

);}

export default Carousel;