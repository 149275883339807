// Imports

// CSS Styles

function NotFound404(){

   return(
<>
    <br/>
    <h2 className="centered secondary">404 Not Found</h2>
    <br/>
</>

);}

export default NotFound404;