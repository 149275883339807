
import GMTKThumbnail from "../images/portfolioThumnails/2021GMTKthumbnail.webp"
import CoopShooter2022Thumbnail from "../images/portfolioThumnails/2022CoopShooterThumbnail.webp"
import Portfolio22Thumbnail from "../images/portfolioThumnails/2022PortfolioThumbnail.webp"
import ReactProjects22Thumbnail from "../images/portfolioThumnails/2022ReactProjectsThumbnail.webp"

import ClassicoThumbnail from "../images/portfolioThumnails/2021ClassicoThumbnail.webp"
import KittysPicklesThumbnail from "../images/portfolioThumnails/2022KittysPicklesThumbnail.webp"
import AnyColorYouLikeThumbnail from "../images/portfolioThumnails/2020AnyColourYouLikeThumbnail.webp"
import MaxiDaydreamsThumbnail from "../images/portfolioThumnails/2019MaxiDayDreamsThumbnail.webp"
import MaxiFascinationsThumbnail from "../images/portfolioThumnails/2019MaxiFascinationsThumbnail.webp"
import TJAlbumArtThumbnail from "../images/portfolioThumnails/2021TJAlbumThumbnail.webp"

import DT0919 from "../images/portfolioThumnails/2019DT09Thumbnail.webp"
import DT1119 from "../images/portfolioThumnails/2019DT11Thumbnail.webp"
import DTTSHIRT from "../images/portfolioThumnails/2020DT01TshirtThumbnail.webp"
import DT0120 from "../images/portfolioThumnails/2020DT01thumbnail.webp"
import DT0521 from "../images/portfolioThumnails/2021DT05Thumbnail.webp"
import DT0621 from "../images/portfolioThumnails/2021DT06Thumbnail.webp"
import DT0322 from "../images/portfolioThumnails/2022DT03Thumbnail.webp"
import DT0422 from "../images/portfolioThumnails/2022DT04Thumbnail.webp"
import DT0522 from "../images/portfolioThumnails/2022DT05Thumbnail.webp"
import DT0622 from "../images/portfolioThumnails/2022DT06Thumbnail.webp"
import DT0722 from "../images/portfolioThumnails/2022DT07Thumbnail.webp"

import Glam0621Thumbnail from "../images/portfolioThumnails/2021Glam06KeyringRotateThumbnail.webp"
import Glam0521Thumbnail from "../images/portfolioThumnails/2021Glam05PipeThumbnail.webp"
import Glam0721Thumbnail from "../images/portfolioThumnails/2021Glam07LighterFallThumbnail.webp"
import Glam0322Thumbnail from "../images/portfolioThumnails/2022Glam03Thumbnail.webp"
import Glam0422Thumbnail from "../images/portfolioThumnails/2022Glam04Thumbnail.webp"
import Glam0522Thumbnail from "../images/portfolioThumnails/2022Glam05Thumbnail.webp"

//2019
import DT0919A4 from "../images/portfolioFullImages/DancingTherapy/2019September/A4.webp"
import DT0919Square from "../images/portfolioFullImages/DancingTherapy/2019September/square.webp"
import DT0919Horizontal from "../images/portfolioFullImages/DancingTherapy/2019September/landscape.webp"



import DT1119A4 from "../images/portfolioFullImages/DancingTherapy/2019November/A4.webp"
import DT1119Square from "../images/portfolioFullImages/DancingTherapy/2019November/square.webp"
import DT1119Horizontal from "../images/portfolioFullImages/DancingTherapy/2019November/Landscape.webp"


//2020
import DT0120A4 from "../images/portfolioFullImages/DancingTherapy/2020Jan/A4.webp"
import DT0120Square from "../images/portfolioFullImages/DancingTherapy/2020Jan/square.webp"
import DT0120Horizontal from "../images/portfolioFullImages/DancingTherapy/2020Jan/landscape.webp"

import AnyColorYouLikeA4 from "../images/portfolioFullImages/OtherPosters/AnyColourYouLikeA4.webp"

//2021
import DT0521A4 from "../images/portfolioFullImages/DancingTherapy/2021May/A4.webp"
import DT0521Square from "../images/portfolioFullImages/DancingTherapy/2021May/square.webp"
import DT0521Horizontal from "../images/portfolioFullImages/DancingTherapy/2021May/landscape.webp"

import DT0621A4 from "../images/portfolioFullImages/DancingTherapy/2021November/A4.webp"
import DT0621Square from "../images/portfolioFullImages/DancingTherapy/2021November/square.webp"
import DT0621Horizontal from "../images/portfolioFullImages/DancingTherapy/2021November/landscape.webp"

import Classico from "../images/portfolioFullImages/OtherPosters/Classico.webp"
import ClassicoSquare from "../images/portfolioFullImages/OtherPosters/ClassicoInsta.webp"


import DT0322A4 from "../images/portfolioFullImages/DancingTherapy/2022March/A4.webp"
import DT0322Square from "../images/portfolioFullImages/DancingTherapy/2022March/square.webp"
import DT0322Horizontal from "../images/portfolioFullImages/DancingTherapy/2022March/landscape.webp"

import DT0422A4 from "../images/portfolioFullImages/DancingTherapy/2022April/A4.webp"
import DT0422Square from "../images/portfolioFullImages/DancingTherapy/2022April/square.webp"
import DT0422Horizontal from "../images/portfolioFullImages/DancingTherapy/2022April/landscape.webp"

import DT0522A4 from "../images/portfolioFullImages/DancingTherapy/2022May/A4.webp"
import DT0522Square from "../images/portfolioFullImages/DancingTherapy/2022May/square.webp"
import DT0522Horizontal from "../images/portfolioFullImages/DancingTherapy/2022May/landscape.webp"

import DT0622A4 from "../images/portfolioFullImages/DancingTherapy/2022June/A4.webp"
import DT0622Square from "../images/portfolioFullImages/DancingTherapy/2022June/square.webp"
import DT0622Horizontal from "../images/portfolioFullImages/DancingTherapy/2022June/landscape.webp"

import DT0722A4 from "../images/portfolioFullImages/DancingTherapy/2022July/A4.webp"
import DT0722Square from "../images/portfolioFullImages/DancingTherapy/2022July/Square.webp"
import DT0722Horizontal from "../images/portfolioFullImages/DancingTherapy/2022July/landscape.webp"
import SpookyCircuitsThumbnail from "../images/portfolioThumnails/SpookyCircuits.webp"


//2022
import Glam0322Horizontal from "../images/portfolioFullImages/Glamorama/Glamorama0322Horizontal.webp"
import Glam0322Vertical from "../images/portfolioFullImages/Glamorama/Glamorama0322Vertical.webp"
import Glam0322Square from "../images/portfolioFullImages/Glamorama/Glamorama0322Square.webp"

import Glam0422Horizontal from "../images/portfolioFullImages/Glamorama/Glamorama0422Horizontal.webp"
import Glam0422Vertical from "../images/portfolioFullImages/Glamorama/Glamorama0422Vertical.webp"
import Glam0422Square from "../images/portfolioFullImages/Glamorama/Glamorama0422Square.webp"

import Glam0522Horizontal from "../images/portfolioFullImages/Glamorama/Glamorama0522Horizontal.webp"
import Glam0522Vertical from "../images/portfolioFullImages/Glamorama/Glamorama0522Vertical.webp"
import Glam0522Square from "../images/portfolioFullImages/Glamorama/Glamorama0522Square.webp"

//2023
import DT0822 from "../images/portfolioThumnails/2022DT08Thumbnail.webp"
import DT0822A4 from "../images/portfolioFullImages/DancingTherapy/2022August/A4.webp"
import DT0822Square from "../images/portfolioFullImages/DancingTherapy/2022August/square.webp"
import DT0822Horizontal from "../images/portfolioFullImages/DancingTherapy/2022August/landscape.webp"

import DT0922 from "../images/portfolioThumnails/2022DT09Thumbnail.webp"
import DT0922A4 from "../images/portfolioFullImages/DancingTherapy/2022September/A4.webp"
import DT0922Square from "../images/portfolioFullImages/DancingTherapy/2022September/square.webp"
import DT0922Horizontal from "../images/portfolioFullImages/DancingTherapy/2022September/landscape.webp"
import DT0922A42 from "../images/portfolioFullImages/DancingTherapy/2022September/Orange/A4.webp"
import DT0922Square2 from "../images/portfolioFullImages/DancingTherapy/2022September/Orange/square.webp"



import DT0423 from "../images/portfolioThumnails/2023DT04Thumbnail.webp"
import DT0423Square from "../images/portfolioFullImages/DancingTherapy/2023April/square.webp"

import MMMThumbnail from "../images/portfolioThumnails/MMMThumbnail.webp"
import MMMSquare from "../images/portfolioFullImages/OtherPosters/MMMsquare.webp"
import MMMLandscape from "../images/portfolioFullImages/OtherPosters/MMMlandscape.webp"

import GameBlogThumbnail from "../images/portfolioThumnails/GameBlogThumbnail.webp"
import MilluThumbnail from "../images/portfolioThumnails/MilluAnimationThumbnail.webp"

import GMTK2023Thumbnail from "../images/portfolioThumnails/GMTK2023Thumbnail.webp"
import GMTK2023Screenshot from "../images/portfolioFullImages/GMTK/GMTK2023Screenshot.webp"
import GMTK2023Screenshot2 from "../images/portfolioFullImages/GMTK/GMTK2023Screenshot2.webp"

import TimeUnlimitedThumbnail from "../images/portfolioThumnails/2023TimeUnlimitedThumbnail.webp"
import TimeUnlimitedSquare from "../images/portfolioFullImages/OtherPosters/TimeUnlimitedSquare.webp"
// import TimeUnlimitedStory from "../images/portfolioFullImages/OtherPosters/TimeUnlimitedStory.webp"
import WeddingWebsiteThumbnail from "../images/portfolioThumnails/2023WeddingWebsiteThumbnail.webp"


import ParcelProThumbnail from "../images/portfolioThumnails/2023ParcelProThumbnail.webp"
import ParcelProImage01 from "../images/portfolioFullImages/ParcelPro/ParcelProImage01.webp"
import ParcelProImage02 from "../images/portfolioFullImages/ParcelPro/ParcelProImage02.webp"


import PoolGuysThumbnail from "../images/portfolioThumnails/2023PoolGuysThumbnail.webp"


export const FULL_DUMMY_DATA = [
    //2019
    {
        id: "2015Spooky-Circuits",
        projectName: "Spooky Circuits",
        subtitle: "3D Animation",
        dateCompleted: new Date( 2015, 9, 1),
        categories: ["Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: SpookyCircuitsThumbnail, 
        images: [{image:SpookyCircuitsThumbnail, alt: ""},],
        link:["https://vimeo.com/114189470"],
        summary: "Spooky Circuits is a 5 minute 3D animation created for the end of my Design course at swinburne" ,
    },
    //2019
    {
        id:"2019Dancing-Therapy-September",
        projectName: "Dancing Therapy September",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2019, 9, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: DT0919,
        images: [{image:DT0919A4, alt: ""}, 
            {image:DT0919Square, alt: ""},
            {image:DT0919Horizontal, alt: ""},],
        summary: "Posters and Social media promo for the club night Dancing Therapy" ,
    },
    {
        id:"2019Maxi-Daydreams-Art",
        projectName: "Maxi Daydreams Art",
        subtitle: "Album Art",
        dateCompleted: new Date( 2019, 6, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: MaxiDaydreamsThumbnail,
        images: [{image:MaxiDaydreamsThumbnail, alt: ""}, ],
        link:["https://open.spotify.com/embed/track/0MvkWWLIii5kZVq0goh7ke?utm_source=generator"],
        summary: "Album Art for the musican MAXI, created with scans of old magazines" ,
    },
    {
        id:"2019Dancing-Therapy-Final",
        projectName: "Dancing Therapy Final",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2019, 11, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: DT1119,
        images: [{image: DT1119A4, alt: "A4 Dancing Therapy Poster"},
                {image: DT1119Square, alt: "Instagram Post Dancing Therapy"},
                {image: DT1119Horizontal, alt: "Landscape Dancing Therapy Poster"}],
        summary: "Posters and Social media promo for the club night Dancing Therapy" ,
    },
    {
        id:"2019Maxi-Facinations-Art",
        projectName: "Maxi Facinations Art",
        subtitle: "Album Art",
        dateCompleted: new Date( 2019, 6, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: MaxiFascinationsThumbnail,
        images: [{image:MaxiFascinationsThumbnail, alt: ""}, ],
        link: ["https://open.spotify.com/embed/track/7wxLL8zCtoYiKiRMTfGZ9Z?utm_source=generator"],
        summary: "Album Art for the musican MAXI, created with scans of old magazines, this time with a 'space' theme" ,

    },
    //2020
    {
        id:"2020Any-Color-You-Like-Poster",
        projectName: "Any Color You Like Poster",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2020, 1, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: AnyColorYouLikeThumbnail,
        images: [{image:AnyColorYouLikeA4, alt: ""}, ],
        summary: "Poster art for a Melbourne art show" ,
    },
    {
        id:"2020Dancing-Therapy-3rd-Birthday",
        projectName: "Dancing Therapy 3rd Birthday",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2020, 1, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: DT0120,
        images: [{image: DT0120A4, alt: "A4 Dancing Therapy Poster"},
                {image: DT0120Square, alt: "Instagram Post Dancing Therapy"},
                {image: DT0120Horizontal, alt: "Landscape Dancing Therapy Poster"}],
        summary: "Posters and Social media promo for the club night Dancing Therapy" ,

    },
    {
        id:"2020Dancing-Therapy-T-Shirt",
        projectName: "Dancing Therapy T-Shirt",
        subtitle: "T-Shirt Design",
        dateCompleted: new Date( 2020, 1, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: DTTSHIRT,
        images: [{image: DTTSHIRT, alt: ""},
        ],
        summary: "Turning the Poster art into a T-Shirt design, making sure the design was screen-print ready, and getting the actual t-shirts printed" ,

    },
    // 2021
    {
        id:"2021Dancing-Therapy-May-Promo",
        projectName: "Dancing Therapy May Promo",
        subtitle: "Poster Design and Animations",
        dateCompleted: new Date( 2021, 5, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0521,
        images: [{image: DT0521A4, alt: "A4 Dancing Therapy Poster"},
                {image: DT0521Square, alt: "Instagram Post Dancing Therapy"},
                {image: DT0521Horizontal, alt: "Landscape Dancing Therapy Poster"}],
        summary: "Posters and Social media promo for the club night Dancing Therapy" ,


    },
    {
        id:"2021Glamorama-May-Promo",
        projectName: "Glamorama May Promo",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2021, 6, 1),
        categories: ["Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: Glam0521Thumbnail,
        images: [{image: Glam0521Thumbnail}],
        summary: "3D renders of a Pipe for the Saturdays at Glamorama during May" ,

    },
    {
        id:"2021Glamorama-June-Promo",
        projectName: "Glamorama June Promo",
        subtitle: "Animations",
        dateCompleted: new Date( 2021, 7, 1),
        categories: [ "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: Glam0621Thumbnail,
        images: [{image: Glam0621Thumbnail}],
        link: ["https://www.instagram.com/p/CWsROU5jUGL/", "https://www.instagram.com/p/CPpfljhjD3O/"],
        summary: "3D Animations of a keyring that was made for Glamorama, each week was to be a different animation during the month of june" ,
    },
    {
        id:"2021Dancing-Therapy-June-Promo",
        projectName: "Dancing Therapy June Promo",
        subtitle: "Poster Design and Animations",
        dateCompleted: new Date( 2021, 6, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0621,
        images: [{image: DT0621A4, alt: "A4 Dancing Therapy Poster"},
                {image: DT0621Square, alt: "Instagram Post Dancing Therapy"},
                {image: DT0621Horizontal, alt: "Landscape Dancing Therapy Poster"}],
        summary: "This poster was inspired by old 80's designs. The figure in the poster is actually a 3D animation of a character dancing, which made it easy to create animations for the promo as well." ,
    },
    {
        id:"2021Glamorama-July-Promo",
        projectName: "Glamorama July Promo",
        subtitle: "Animations",
        dateCompleted: new Date( 2021, 8, 1),
        categories: ["Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: Glam0721Thumbnail,
        images: [{image:Glam0721Thumbnail}],
        link: ["https://scontent-syd2-1.cdninstagram.com/v/t50.16885-16/259375038_665652791089937_3291002839937947734_n.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5pZ3R2LmRlZmF1bHQiLCJxZV9ncm91cHMiOiJbXCJpZ193ZWJfZGVsaXZlcnlfdnRzX290ZlwiXSJ9&_nc_ht=scontent-syd2-1.cdninstagram.com&_nc_cat=105&_nc_ohc=_EGO-xwwhZIAX_cbGxp&edm=ALQROFkBAAAA&vs=17930146768767171_2657391612&_nc_vs=HBksFQAYJEdMNi1kUThSR3loZWFGMENBRmFZVFgybC1hc3RidlZCQUFBRhUAAsgBABUAGCRHTjY5YkE5endndG1lSmNQQVBOWDRsU0I4c1JnYnZWQkFBQUYVAgLIAQAoABgAGwGIB3VzZV9vaWwBMRUAACagktvCmJXSPxUCKAJDMywXQC4AAAAAAAAYEmRhc2hfYmFzZWxpbmVfMV92MREAdewHAA%3D%3D&_nc_rid=14ba78d3c3&ccb=7-5&oe=62A3D109&oh=00_AT_6rCbpJcv6twYtIRa3hB3H15dnsSqaG8n4VUts_8TUOw&_nc_sid=30a2ef",
        "https://scontent-syd2-1.cdninstagram.com/v/t50.16885-16/252917444_580581893236125_8512191168762062539_n.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5pZ3R2LmRlZmF1bHQiLCJxZV9ncm91cHMiOiJbXCJpZ193ZWJfZGVsaXZlcnlfdnRzX290ZlwiXSJ9&_nc_ht=scontent-syd2-1.cdninstagram.com&_nc_cat=109&_nc_ohc=G7WmBaKXChAAX-6c6ZV&edm=ALQROFkBAAAA&vs=17879695934553014_3118476870&_nc_vs=HBksFQAYJEdNUTJFd19kWllKQkNSQUNBTXVtUjdyRVlDRjJidlZCQUFBRhUAAsgBABUAGCRHRlg0Qmc4bTdLOVFQcDREQU9tWUktWTE1Vk42YnZWQkFBQUYVAgLIAQAoABgAGwGIB3VzZV9vaWwBMRUAACae1svysanPQBUCKAJDMywXQC4AAAAAAAAYEmRhc2hfYmFzZWxpbmVfMV92MREAdewHAA%3D%3D&_nc_rid=50a8e830a4&ccb=7-5&oe=62A3FC83&oh=00_AT_alo6PYaL8PWeQI8m-1rRnOEUkuh5yDNvYY-vTwYQTOg&_nc_sid=30a2ef        "
        , "https://scontent-syd2-1.cdninstagram.com/v/t50.16885-16/255175682_575313450250307_4854071763525046640_n.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5pZ3R2LmRlZmF1bHQiLCJxZV9ncm91cHMiOiJbXCJpZ193ZWJfZGVsaXZlcnlfdnRzX290ZlwiXSJ9&_nc_ht=scontent-syd2-1.cdninstagram.com&_nc_cat=111&_nc_ohc=E5LzjJokl74AX-EIjqN&edm=ALQROFkBAAAA&vs=17911795493055232_312571852&_nc_vs=HBksFQAYJEdBS3NOUTlETU5hWlBnc0NBSEFSS2d1TklGMURidlZCQUFBRhUAAsgBABUAGCRHSnZyT1FfVWYyZzJNbHdFQUQ5ZzMyajExS3hGYnZWQkFBQUYVAgLIAQAoABgAGwGIB3VzZV9vaWwBMRUAACbqwJ%2BW4qfLPxUCKAJDMywXQC4AAAAAAAAYEmRhc2hfYmFzZWxpbmVfMV92MREAdewHAA%3D%3D&_nc_rid=30ca370611&ccb=7-5&oe=62A3671A&oh=00_AT-N7DeQCxwCtg5Ot-00IzRWJcCPDCNlCMO4jGSrrAzusA&_nc_sid=30a2ef    "], 
        summary: "3D animations of a Glamorama lighter, 4 Animations were done in total, mostly using physics to showcase the lighter" ,

    },
    {
        id:"2021Classico-Poster",
        projectName: "Classico Poster",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2021, 6, 21),
        categories: ["Design"], 
        programs: ["Photoshop"],
        thumbnail: ClassicoThumbnail,
        images: [{image: Classico, alt: "A4 Dancing Therapy Poster"},
                {image: ClassicoSquare, alt: "Instagram Post Dancing "},],
        summary: "A simple 'italian winter' inspired poster " ,
    },
    {
        id: "2021Tony-Trimboli-Album-Art",
        projectName: "Tony Trimboli Album Art",
        subtitle: "Album Art",
        dateCompleted: new Date( 2021, 6, 1),
        categories: ["Design"],
        programs: ["Photoshop", "AfterEffects"],
        thumbnail: TJAlbumArtThumbnail,
        images: [{image: TJAlbumArtThumbnail}],
        link: ['https://www.youtube.com/watch?v=j1MupcMdKF4&'],
        summary: "A simple effect added to an old picture for the release of a new song " ,

    },
    {
        id: "2021GMTK-Game-Jam",
        projectName: "GMTK Game Jam",
        subtitle: "MiniGame",
        dateCompleted: new Date( 2021, 6, 1),
        categories: ["Programming"], 
        programs: ["Unreal"],
        thumbnail: GMTKThumbnail,
        images: [{image: GMTKThumbnail}],
        link: ["https://itch.io/jam/gmtk-2021/rate/1085016"],
        summary: "This is a quick game made in 48hrs as a part of the 'Game Makers tool kit' Game Jam, The theme was 'Joined together' so I deciced to make a game where you control a pet and try to get your owner home. The game is buggy and unbalanced but a great learning experience and a lot of fun " ,


    },
    ///2022
    {
        id: "2022Dancing-Therapy-March-Promo",
        projectName: "Dancing Therapy March Promo",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2022, 3, 1),
        categories: ["Design"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0322,
        images: [{image: DT0322A4, alt: "A4 Dancing Therapy Poster"},
                {image: DT0322Square, alt: "Instagram Post Dancing Therapy"},
                {image: DT0322Horizontal, alt: "Landscape Dancing Therapy Poster"}],
        summary: "This Promo for Dancing Therapy featured a 3D model of an old Apple Computer with a similar background to the previous month" ,
    },
    {
        id: "2022Kittys-Pickles",
        projectName: "Kitty's Pickles",
        subtitle: "Logo and Brand Design",
        dateCompleted: new Date( 2022, 2, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: KittysPicklesThumbnail,
        images:[{image: KittysPicklesThumbnail}],
        summary: "This logo was created to be added on to homemade pickle jars and sold locally in Melbourne" ,
    },
    {
        id: "2022Dancing-Therapy-April-Promo",
        projectName: "Dancing Therapy April Promo",
        subtitle: "Poster Design and Animation",
        dateCompleted: new Date( 2022, 4, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0422,
        images: [{image: DT0422A4, alt: "A4 Dancing Therapy Poster"},
                {image: DT0422Square, alt: "Instagram Post Dancing Therapy"},
                {image: DT0422Horizontal, alt: "Landscape Dancing Therapy Poster"}],
        summary: "This Poster was inspired by the old windows 95 computers and word-art" ,

    },
    {
        id: "2022Glamorama-March-Promo",
        projectName: "Glamorama March Promo",
        subtitle: "Animations",
        dateCompleted: new Date( 2022, 3, 1),
        categories: ["Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: Glam0322Thumbnail,
        images: [ {image: Glam0322Square, alt: ""},
                {image: Glam0322Vertical, alt: ""},
                {image: Glam0322Horizontal, alt: ""},],
        link: ["https://scontent-syd2-1.cdninstagram.com/v/t50.16885-16/275063589_507133664180682_3384235480313512684_n.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5pZ3R2LmJhc2VsaW5lIiwicWVfZ3JvdXBzIjoiW1wiaWdfd2ViX2RlbGl2ZXJ5X3Z0c19vdGZcIl0ifQ&_nc_ht=scontent-syd2-1.cdninstagram.com&_nc_cat=106&_nc_ohc=cm1feAUevwkAX9omsU_&edm=ALQROFkBAAAA&vs=639119367419312_3023515132&_nc_vs=HBksFQAYJEdDVWpaUkRLT2VoQlBNMEJBT3hDWENnOU9QY3VidlZCQUFBRhUAAsgBABUAGCRHRnhRWkJEbnI0NHJjTVFFQUhnM1NIYkNjcmtkYnZWQkFBQUYVAgLIAQAoABgAGwGIB3VzZV9vaWwBMRUAACbGra67%2FJzuPxUCKAJDMywXQD4AAAAAAAAYEmRhc2hfYmFzZWxpbmVfMV92MREAdewHAA%3D%3D&_nc_rid=3051e25ef6&ccb=7-5&oe=62A3AA78&oh=00_AT-j0e0rsd7GRoqdl41NcHaKzR8DoLlaopasnlrjhBwaGw&_nc_sid=30a2ef        ",], 
        summary: "After all the 3D animations, these animations were created mainly in After Effects and were going for theme that could more easily be templated for the next months" ,
  
    },
    {
        id: "2022Untitiled-Co-op-shooter", 
        projectName: "Untitiled Co-op shooter",
        subtitle: "Game Showcase",
        dateCompleted: new Date( 2022, 11, 1),
        categories: ["Programming"],
        programs: ["Unreal"],
        thumbnail: CoopShooter2022Thumbnail,
        images: [{ image: CoopShooter2022Thumbnail,}],
        link: ["https://www.youtube.com/watch?v=lsaYEP_V_J0", "https://youtu.be/Q0Ug_1JgztU", "https://youtu.be/110kQ6GYnto"],
        summary: "This untitled shooter is what i've been working on for over a year now, I am working on creating a blog for the game, to be able to have frequent updates on what I've been working on, Below I have a couple of quick videos of some features. The game is aimed to be released by the end of this year." ,
    },
    {
        id: "2022Glamorama-April-Promo", 
        projectName: "Glamorama April Promo",
        subtitle: "Animations",
        dateCompleted: new Date( 2022, 4, 1),
        categories: ["Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: Glam0422Thumbnail,
        images: [ {image: Glam0422Square, alt: ""},
        {image: Glam0422Vertical, alt: ""},
        {image: Glam0422Horizontal, alt: ""},],
        link: ["https://scontent-syd2-1.cdninstagram.com/v/t50.16885-16/277525062_550487199794612_6590015519563282427_n.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5pZ3R2LmJhc2VsaW5lIiwicWVfZ3JvdXBzIjoiW1wiaWdfd2ViX2RlbGl2ZXJ5X3Z0c19vdGZcIl0ifQ&_nc_ht=scontent-syd2-1.cdninstagram.com&_nc_cat=108&_nc_ohc=_w6oOpT9hD0AX9qRA3D&tn=3t7xretK5M180Q3p&edm=ALQROFkBAAAA&vs=4918859008201168_1481187014&_nc_vs=HBksFQAYJEdFYXlpaEMwUmY5SnF2UUJBUHQzNFJzemNIUmJidlZCQUFBRhUAAsgBABUAGCRHR3BWalJCNXZ2TTZiaUFFQURpTXV2UVowV1E4YnZWQkFBQUYVAgLIAQAoABgAGwGIB3VzZV9vaWwBMRUAACaglvGQwbfSPxUCKAJDMywXQD4AAAAAAAAYEmRhc2hfYmFzZWxpbmVfMV92MREAdewHAA%3D%3D&_nc_rid=934121a364&ccb=7-5&oe=62A37F0D&oh=00_AT86OO-n7OltMJIqPeCl2RdgL-DU-dbdYaOYc6691MVp8Q&_nc_sid=30a2ef        ",],
        summary: "Going with the similar theme as the last Glamorama promo, this one was set to be more 'Matrix' vibes." ,

    },
    {
        id: "2022Portfolio-Website", 
        projectName: "Portfolio Website",
        subtitle: "React Project",
        dateCompleted: new Date( 2022, 5, 1),
        categories: ["Programming"],
        programs: ["React", "VisualStudioCode"],
        thumbnail: Portfolio22Thumbnail,
        images: [{image: Portfolio22Thumbnail}],
        summary: "Why is this website a page on my portfolio website?? Well I think it deserves to be, as this wasn't just a simple creation. My old portfolio was created entirely in just HTML+CSS, and after learning React.js I decided to challenge myself and recreate the entire website in react. It was a big learning experience but I feel a lot more confident in React after doing so, and now I am working on my Portfolio Website 2.0 from scrach with all the new skills I've learnt" ,
    },
    {
        id: "2022React-Projects", 
        projectName: "React Projects",
        subtitle: "React Sample Projects",
        dateCompleted: new Date( 2022, 5, 1),
        categories: ["Programming"],
        programs: ["React", "VisualStudioCode"],
        thumbnail: ReactProjects22Thumbnail,
        images: [ {image: ReactProjects22Thumbnail, alt: ""},],
        summary: "To practice my React skills I have been working on some smaller projects to showcase my programming abilities. Currently I have completed: Calculator, Notes App, Todo List, Countdown Timer, Expense Tracker, UI Library. These are all up on my Github, but to truely make them more engaging they will be fully intergrated into my new portfolio" ,
    },
    {
        id: "2022Glamorama-May-Promo", 
        projectName: "Glamorama May Promo",
        subtitle: "Animations",
        dateCompleted: new Date( 2022, 5, 1),
        categories: ["Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: Glam0522Thumbnail,
        images: [ {image: Glam0522Square, alt: ""},
                {image: Glam0522Vertical, alt: ""},
                {image: Glam0522Horizontal, alt: ""},],
        link: ["https://scontent-syd2-1.cdninstagram.com/v/t50.16885-16/279645304_2808478546128666_2511327509196720466_n.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5pZ3R2LmJhc2VsaW5lIiwicWVfZ3JvdXBzIjoiW1wiaWdfd2ViX2RlbGl2ZXJ5X3Z0c19vdGZcIl0ifQ&_nc_ht=scontent-syd2-1.cdninstagram.com&_nc_cat=106&_nc_ohc=afbzcTGHut0AX8cV320&edm=ALQROFkBAAAA&vs=966567187388457_2943739884&_nc_vs=HBksFQAYJEdIZ01xeEFhN3huZVMtb0pBRkpKVm1RUUI5b2lidlZCQUFBRhUAAsgBABUAGCRHTkZjcnhBZ0NaWnRhbXdEQU9jX293ZUxqOFFxYnZWQkFBQUYVAgLIAQAoABgAGwGIB3VzZV9vaWwBMRUAACb%2B24O%2B%2FfTwPxUCKAJDMywXQD4AAAAAAAAYEmRhc2hfYmFzZWxpbmVfMV92MREAdewHAA%3D%3D&_nc_rid=0453b24241&ccb=7-5&oe=62A3EBA7&oh=00_AT8JUbcyIJDJJkcip80AdbjqaecfCGNmx9hxla712vp4Hw&_nc_sid=30a2ef        ",],
        summary: "This months Glamorama posters theme was to hype up an international artist." ,

    },
    {
        id: "2022Dancing-Therapy-May-Promo", 
        projectName: "Dancing Therapy May Promo",
        subtitle: "Animations",
        dateCompleted: new Date( 2022, 5, 1),
        categories: ["Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0522,
        images: [ {image: DT0522A4, alt: ""},
                {image: DT0522Square, alt: ""},
                {image: DT0522Horizontal, alt: ""},],
        summary: "This months Dancing Therapy poster was continuing the last months theme." ,

        // link: ["https://scontent-syd2-1.cdninstagram.com/v/t50.16885-16/279645304_2808478546128666_2511327509196720466_n.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5pZ3R2LmJhc2VsaW5lIiwicWVfZ3JvdXBzIjoiW1wiaWdfd2ViX2RlbGl2ZXJ5X3Z0c19vdGZcIl0ifQ&_nc_ht=scontent-syd2-1.cdninstagram.com&_nc_cat=106&_nc_ohc=afbzcTGHut0AX8cV320&edm=ALQROFkBAAAA&vs=966567187388457_2943739884&_nc_vs=HBksFQAYJEdIZ01xeEFhN3huZVMtb0pBRkpKVm1RUUI5b2lidlZCQUFBRhUAAsgBABUAGCRHTkZjcnhBZ0NaWnRhbXdEQU9jX293ZUxqOFFxYnZWQkFBQUYVAgLIAQAoABgAGwGIB3VzZV9vaWwBMRUAACb%2B24O%2B%2FfTwPxUCKAJDMywXQD4AAAAAAAAYEmRhc2hfYmFzZWxpbmVfMV92MREAdewHAA%3D%3D&_nc_rid=0453b24241&ccb=7-5&oe=62A3EBA7&oh=00_AT8JUbcyIJDJJkcip80AdbjqaecfCGNmx9hxla712vp4Hw&_nc_sid=30a2ef        ",],
    },
    {
        id: "2022Dancing-Therapy-June-Promo", 
        projectName: "Dancing Therapy June Promo",
        subtitle: "Animations",
        dateCompleted: new Date( 2022, 6, 1),
        categories: ["Animation"],
        programs: ["Blender", "AfterEffects", "Photoshop"],
        thumbnail: DT0622,
        images: [ {image: DT0622A4, alt: ""},
                {image: DT0622Square, alt: ""},
                {image: DT0622Horizontal, alt: ""},],
        summary: "This months Dancing Therapy poster was going for an old mathematics text book look, with simple 3D objects." ,

        // link: ["https://scontent-syd2-1.cdninstagram.com/v/t50.16885-16/279645304_2808478546128666_2511327509196720466_n.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5pZ3R2LmJhc2VsaW5lIiwicWVfZ3JvdXBzIjoiW1wiaWdfd2ViX2RlbGl2ZXJ5X3Z0c19vdGZcIl0ifQ&_nc_ht=scontent-syd2-1.cdninstagram.com&_nc_cat=106&_nc_ohc=afbzcTGHut0AX8cV320&edm=ALQROFkBAAAA&vs=966567187388457_2943739884&_nc_vs=HBksFQAYJEdIZ01xeEFhN3huZVMtb0pBRkpKVm1RUUI5b2lidlZCQUFBRhUAAsgBABUAGCRHTkZjcnhBZ0NaWnRhbXdEQU9jX293ZUxqOFFxYnZWQkFBQUYVAgLIAQAoABgAGwGIB3VzZV9vaWwBMRUAACb%2B24O%2B%2FfTwPxUCKAJDMywXQD4AAAAAAAAYEmRhc2hfYmFzZWxpbmVfMV92MREAdewHAA%3D%3D&_nc_rid=0453b24241&ccb=7-5&oe=62A3EBA7&oh=00_AT8JUbcyIJDJJkcip80AdbjqaecfCGNmx9hxla712vp4Hw&_nc_sid=30a2ef        ",],
    },
    {
        id: "2022Dancing-Therapy-July-Promo", 
        projectName: "Dancing Therapy July Promo",
        subtitle: "Poster Design Animations",
        dateCompleted: new Date( 2022, 7, 1),
        categories: ["Animation"],
        programs: ["Blender", "AfterEffects", "Photoshop"],
        thumbnail: DT0722,
        images: [ {image: DT0722A4, alt: ""},
                {image: DT0722Square, alt: ""},
                {image: DT0722Horizontal, alt: ""},],
        summary: "For this poster I was inspired by seeing a lot of current art that had the look of an old scanned magazine, so I decided to model an old cassette player I have, along with the cassette in it. Those 3D objects were taken into photoshop were I made the whole image try to look old and damaged." ,

        // link: ["https://scontent-syd2-1.cdninstagram.com/v/t50.16885-16/279645304_2808478546128666_2511327509196720466_n.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5pZ3R2LmJhc2VsaW5lIiwicWVfZ3JvdXBzIjoiW1wiaWdfd2ViX2RlbGl2ZXJ5X3Z0c19vdGZcIl0ifQ&_nc_ht=scontent-syd2-1.cdninstagram.com&_nc_cat=106&_nc_ohc=afbzcTGHut0AX8cV320&edm=ALQROFkBAAAA&vs=966567187388457_2943739884&_nc_vs=HBksFQAYJEdIZ01xeEFhN3huZVMtb0pBRkpKVm1RUUI5b2lidlZCQUFBRhUAAsgBABUAGCRHTkZjcnhBZ0NaWnRhbXdEQU9jX293ZUxqOFFxYnZWQkFBQUYVAgLIAQAoABgAGwGIB3VzZV9vaWwBMRUAACb%2B24O%2B%2FfTwPxUCKAJDMywXQD4AAAAAAAAYEmRhc2hfYmFzZWxpbmVfMV92MREAdewHAA%3D%3D&_nc_rid=0453b24241&ccb=7-5&oe=62A3EBA7&oh=00_AT8JUbcyIJDJJkcip80AdbjqaecfCGNmx9hxla712vp4Hw&_nc_sid=30a2ef        ",],
    },
    {
        id: "2022Dancing-Therapy-August-Promo", 
        projectName: "Dancing Therapy August Promo",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2022, 8, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0822,
        images: [ {image: DT0822A4, alt: ""},
        {image: DT0822Square, alt: ""},
        {image: DT0822Horizontal, alt: ""},],
        summary: "This poster was similar to the July poster but going with a japanese vinly sleeve look" ,

    },
    {
        id: "2022Dancing-Therapy-September-Promo", 
        projectName: "Dancing Therapy September Promo",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2022, 9, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0922,
        images: [ {image: DT0922A4, alt: ""},
        {image: DT0922Square, alt: ""},
        {image: DT0922Square2, alt: ""},
        {image: DT0922A42, alt: ""},
        {image: DT0922Horizontal, alt: ""},],
        summary: "This was for the Final event for the Dancing Therapy crew, made to look bold, simple, and eye catching" ,

    },
    {
        id: "2023Dancing-Therapy-April-Promo", 
        projectName: "Dancing Therapy April Promo",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2023, 4, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: DT0423,
        images: [ {image: DT0423Square, alt: ""},],
        summary: "This was another look into the retro futuristic design" ,

    },
    {
        id: "2023Much-More-Music", 
        projectName: "Much More Music",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2022, 12, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: MMMThumbnail,
        images: [ {image: MMMSquare, alt: ""},
        {image: MMMLandscape, alt: ""}],
        summary: "This poster was inspired by Bold 80s colors" ,
    },
    {
        id: "2023Send-Return", 
        projectName: "Send Return",
        subtitle: "Poster Animation",
        dateCompleted: new Date( 2023, 5, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects", "Photoshop"],
        thumbnail: MilluThumbnail,
        images: [ {image: MilluThumbnail, alt: ""},],
        summary: "A short 15 Second animation made from a still image" ,
    },
    {
        id: "2023Game-Blog", 
        projectName: "Game Blog",
        subtitle: "Blog for all my Unreal Projects",
        dateCompleted: new Date( 2023, 5, 1),
        categories: ["Design", "Programming"],
        programs: ["Unreal"],
        thumbnail: GameBlogThumbnail,
        images: [ {image: GameBlogThumbnail, alt: ""},],
        summary: "This is a blog website, built with Next.js, to showcase all my updates with my Game projects" ,
        link: ["https://www.BucciGames.com",],
    },
    {
        id: "2023Wedding-Website", 
        projectName: "Wedding Website",
        subtitle: "Wedding RSVP list and info page",
        dateCompleted: new Date( 2023, 6, 1),
        categories: ["Design", "Programming"],
        programs: ["React", "VisualStudioCode"],
        thumbnail: WeddingWebsiteThumbnail,
        images: [ {image: WeddingWebsiteThumbnail, alt: ""},],
        summary: "A simple single page website, used for guests to RSVP, submit guest details, (which automaticly sign up to a mailing list), and options to donate." ,
        link: ["https://www.CurtisAndMia.com",],
    },
    {
        id: "2023Time-Unlimited", 
        projectName: "Time Unlimited",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2023, 7, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: TimeUnlimitedThumbnail,
        images: [ {image: TimeUnlimitedSquare, alt: ""}],
        summary: "A retro 80s style poster, for an italo disco themed night." ,
        // link: ["https://www.BucciGames.com",],
    },
    {
        id: "2023Sea-Shifters", 
        projectName: "Sea Shifters",
        subtitle: "GMTK Game Jam Entry",
        dateCompleted: new Date( 2023, 7, 1),
        categories: ["Design", "Programming"],
        programs: ["Unreal"],
        thumbnail: GMTK2023Thumbnail,
        images: [ {image: GMTK2023Screenshot2, alt: ""}, {image: GMTK2023Screenshot, alt: ""}],
        summary: "Sea Shifters was my 2023 submission to the GMTK Game Jam. The entire game was created in 48 hours over one weekend. The theme this year was 'Roles Reversed', so I decided to create a game where every 15 seconds you change roles, cycling from life saver, to swimmer, to shark." ,
        link: ["https://itch.io/jam/gmtk-2023/rate/2160169",],
    },
    {
        id: "2023Pool-Guys", 
        projectName: "Pool Guys",
        subtitle: "Pool Cleaning Website",
        dateCompleted: new Date( 2023, 9, 1),
        categories: ["Design", "Programming"],
        programs: ["React", "Visual Studio Code"],
        thumbnail: PoolGuysThumbnail,
        summary: "Pool Guys wanted a simple and clean looking website. Featuring a contact page, info pages, and an Admin Dashboard for easy updating without coding knowledge." ,
        images: [ {image: PoolGuysThumbnail, alt: ""}, ],
        link: ["https://poolguys.vercel.app/",],
    },
    {
        id: "2023Parcel-Pro", 
        projectName: "Parcel Pro",
        subtitle: "Postage Packing Software",
        dateCompleted: new Date( 2023, 11, 1),
        categories: ["Design", "Programming"],
        programs: ["React", "Visual Studio Code", "Next.js", "Three.js", "Python"],
        thumbnail: ParcelProThumbnail,
        summary: "Parcel Pro is a local tech startup that I joined in 2023 as lead software developer. Tasked with trying to minimize empty space in postage boxes, we created an MVP application that is able to sort and optimize the packing process.\n Using mainly Python for the 3D bin packing API, and Next.js for the frontend of the application with a 3D Visual component made with THREE.js. \nFulfilment optimisation solutions for e-commerce ParcelPro using advanced algorithms and machine learning to make tailored recommendations for optimising e-commerce order fulfilment. By ensuring every customer order is packed in the most efficient box, businesses reduce shipping costs and minimise carbon emissions." ,
        images: [ {image: ParcelProImage01, alt: ""},{ image: ParcelProImage02, alt: ""}, {image: ParcelProThumbnail, alt: ""}],
        link: ["https://www.parcelpro.co/",],

    },
]
