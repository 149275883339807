// Imports
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import PortfolioItem from "./PortfolioItem";

// CSS Styles
import CSS from "./PortfolioList.module.css"

import { DUMMY_DATA } from "../../data/DummyData";

//Simple Sorting function by ID
const sortItems = (item, ascending) => {
    return item.sort((itemA, itemB) => {
      if (ascending) {
        return itemA.id > itemB.id ? 1 : -1;
      } else {
        return itemA.id < itemB.id ? 1 : -1;
      }
    });
};


function PortfolioList(props){
    const history = useHistory();
    //location of user on site
    const location = useLocation();
    //This converts the location in to a more useable object
    const queryParams = new URLSearchParams(location.search);
    const isSortingAscending = queryParams.get("sort") === "asc";

    //Sort by Newest or oldest
    function sortListHandler(){
        history.push({
            pathname: location.pathname,
            search: `?sort=${(isSortingAscending ? "dsc" : "asc")}`,
        });
    }

    const sortedPortfolioList = sortItems(DUMMY_DATA, isSortingAscending);

    const [filterCategory, setFilterCategory] = useState("All");

    function filterByCategory(item) {
        if(filterCategory === "All")
        {
            return true;
        }
        for (let i = 0; i < item.categories.length; i++) 
        {
            if(filterCategory === item.categories[i])
            {
                //Another for loop? for multiple categories
                return true;
            }
        }
    }

    let filteredPortfolioList = sortedPortfolioList.filter(filterByCategory);

    function ShowAll(){
        setFilterCategory("All");
    }
    function ShowAnimation(){
        setFilterCategory("Animation");
    }    
    function ShowDesign(){
        setFilterCategory("Design");
    }    
    function ShowProgramming(){
        setFilterCategory("Programming");
    }


{    // const [portfolioItems, setPortfolioItems] = useState([]);
    // const {isLoading, error, sendRequest: fetchPortfolioItems} = useHttp();
    // useEffect(() => {
    //     const transformPortfolioItems = portItems =>
    //     {
    //         setPortfolioItems(portItems);
    //     }
    //     fetchPortfolioItems({url: "https://curtisbucciol-default-rtdb.asia-southeast1.firebasedatabase.app/portfolio.json"}, transformPortfolioItems);
    // }, [fetchPortfolioItems]);
    // if(portfolioItems.length === 0)
    // {
    //     return <h2>Error List is empty</h2>
    // }
    // if(error)
    // {
    //     return (
    //         <div className="centered focused">
    //             <p>Error fetching data {error}</p>
    //         </div>
    //     );
    // }
}

    //Starts from the newest and Counts down the props.amount of hightlights
    function getFirstSix(item)
    {
        for(let i = DUMMY_DATA.length; props.amount > DUMMY_DATA.length - i; i--)
        {
            if(item.id === i)
            {
                return true;
            }
        }
    }

    if(props.highlights)
    {
        filteredPortfolioList = DUMMY_DATA.filter(getFirstSix);
    }
    
    return(
    <div>
        {!props.highlights && 
        <div className={CSS.filters}>
            <hr className="breaker"/>
            <div>
                <button className="btn" onClick={sortListHandler}>Sort: {isSortingAscending ? "Descending ↓" : "Ascending ↑"}</button>
            </div>
            <div>
                <button className="btn" onClick={ShowAll}>Show All</button>
                <button className="btn" onClick={ShowAnimation}>Animation</button>
                <button className="btn" onClick={ShowDesign}>Design</button>
                <button className="btn" onClick={ShowProgramming}>Programming</button>
            </div>
            <hr className="breaker"/>
        </div>
        }
        <ul className={CSS.list}>
            {filteredPortfolioList.map((item) => (
               <PortfolioItem 
                    key={item.id} 
                    id={item.id} 
                    projectName={item.projectName}
                    subtitle={item.subtitle}
                    thumbnail={item.thumbnail}
                    date={item.dateCompleted.getFullYear()}
                    categories={item.categories}
                    highlights={props.highlights}
                />
            ))}
        </ul>
    </div>
);}


export default PortfolioList;