import { useState } from "react";

function useInput(validateValue){
    const [ enteredValue, setEnteredValue] = useState("");
    const [ isTouched, setIsTouched] = useState(false);

    const valueIsValid = validateValue(enteredValue);
    const hasError = !valueIsValid && isTouched;

    function valueChangedHandler(event)
    {
        setEnteredValue(event.target.value);
    }
    function valueBlurHandler(event)
    {
        setIsTouched(true);
    }

    function resetValue()
    {
        setEnteredValue("");
        setIsTouched(false);
    }


   return{
        value: enteredValue,
        isValid: valueIsValid,
        hasError: hasError,
        valueChangedHandler: valueChangedHandler,
        valueBlurHandler: valueBlurHandler,
        resetValue: resetValue,
   };}

export default useInput;