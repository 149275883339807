
import GMTKThumbnail from "../images/portfolioThumnails/2021GMTKthumbnail.webp"
import SpookyCircuitsThumbnail from "../images/portfolioThumnails/SpookyCircuits.webp"

import ClassicoThumbnail from "../images/portfolioThumnails/2021ClassicoThumbnail.webp"
import MMMThumbnail from "../images/portfolioThumnails/MMMThumbnail.webp"

import Glam0621Thumbnail from "../images/portfolioThumnails/2021Glam06KeyringRotateThumbnail.webp"
import Glam0521Thumbnail from "../images/portfolioThumnails/2021Glam05PipeThumbnail.webp"
import Glam0721Thumbnail from "../images/portfolioThumnails/2021Glam07LighterFallThumbnail.webp"

import CoopShooter2022Thumbnail from "../images/portfolioThumnails/2022CoopShooterThumbnail.webp"
import GameBlogThumbnail from "../images/portfolioThumnails/GameBlogThumbnail.webp"



import KittysPicklesThumbnail from "../images/portfolioThumnails/2022KittysPicklesThumbnail.webp"
import DT0120 from "../images/portfolioThumnails/2020DT01thumbnail.webp"
import DTTSHIRT from "../images/portfolioThumnails/2020DT01TshirtThumbnail.webp"
import DT0521 from "../images/portfolioThumnails/2021DT05Thumbnail.webp"
import DT0621 from "../images/portfolioThumnails/2021DT06Thumbnail.webp"
import DT0322 from "../images/portfolioThumnails/2022DT03Thumbnail.webp"
import DT0422 from "../images/portfolioThumnails/2022DT04Thumbnail.webp"
import DT0522 from "../images/portfolioThumnails/2022DT05Thumbnail.webp"
import DT0622 from "../images/portfolioThumnails/2022DT06Thumbnail.webp"
import DT0722 from "../images/portfolioThumnails/2022DT07Thumbnail.webp"
import DT0822 from "../images/portfolioThumnails/2022DT08Thumbnail.webp"
import DT0922 from "../images/portfolioThumnails/2022DT09Thumbnail.webp"
import DT0423 from "../images/portfolioThumnails/2023DT04Thumbnail.webp"

import Glam0322Thumbnail from "../images/portfolioThumnails/2022Glam03Thumbnail.webp"
import Glam0422Thumbnail from "../images/portfolioThumnails/2022Glam04Thumbnail.webp"
import Glam0522Thumbnail from "../images/portfolioThumnails/2022Glam05Thumbnail.webp"

import Portfolio22Thumbnail from "../images/portfolioThumnails/2022PortfolioThumbnail.webp"
import ReactProjects22Thumbnail from "../images/portfolioThumnails/2022ReactProjectsThumbnail.webp"

import DT0919 from "../images/portfolioThumnails/2019DT09Thumbnail.webp"
import DT1119 from "../images/portfolioThumnails/2019DT11Thumbnail.webp"
import AnyColorYouLikeThumbnail from "../images/portfolioThumnails/2020AnyColourYouLikeThumbnail.webp"

import MaxiDaydreamsThumbnail from "../images/portfolioThumnails/2019MaxiDayDreamsThumbnail.webp"
import MaxiFascinationsThumbnail from "../images/portfolioThumnails/2019MaxiFascinationsThumbnail.webp"
import TJAlbumArtThumbnail from "../images/portfolioThumnails/2021TJAlbumThumbnail.webp"
import MilluThumbnail from "../images/portfolioThumnails/MilluAnimationThumbnail.webp"

import GMTK2023Thumbnail from "../images/portfolioThumnails/GMTK2023Thumbnail.webp"

import WeddingWebsiteThumbnail from "../images/portfolioThumnails/2023WeddingWebsiteThumbnail.webp"

import TimeUnlimitedThumbnail from "../images/portfolioThumnails/2023TimeUnlimitedThumbnail.webp"

import ParcelProThumbnail from "../images/portfolioThumnails/2023ParcelProThumbnail.webp"
import PoolGuysThumbnail from "../images/portfolioThumnails/2023PoolGuysThumbnail.webp"



export const DUMMY_DATA = [
    //2020
    {
        id: 0,
        projectName: "Spooky Circuits",
        subtitle: "3D Animation",
        dateCompleted: new Date( 2015, 9, 1),
        categories: ["Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: SpookyCircuitsThumbnail, 
    },
    {
        id:1,
        projectName: "Dancing Therapy September",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2019, 9, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: DT0919,
    },
    {
        id:2,
        projectName: "Maxi Daydreams Art",
        subtitle: "Album Art",
        dateCompleted: new Date( 2019, 6, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: MaxiDaydreamsThumbnail,
    },
    {
        id:3,
        projectName: "Dancing Therapy Final",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2019, 11, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: DT1119,
    },
    {
        id:4,
        projectName: "Maxi Facinations Art",
        subtitle: "Album Art",
        dateCompleted: new Date( 2019, 6, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: MaxiFascinationsThumbnail,
    },
    {
        id:5,
        projectName: "Any Color You Like Poster",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2020, 1, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: AnyColorYouLikeThumbnail,
    },
    {
        id:6,
        projectName: "Dancing Therapy 3rd Birthday",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2020, 1, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: DT0120,
            },
    {
        id:7,
        projectName: "Dancing Therapy T-Shirt",
        subtitle: "T-Shirt Design",
        dateCompleted: new Date( 2020, 1, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: DTTSHIRT,
    },
    // 2021
    {
        id:8,
        projectName: "Dancing Therapy May Promo",
        subtitle: "Poster Design and Animations",
        dateCompleted: new Date( 2021, 5, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0521,
    },
    {
        id:9,
        projectName: "Glamorama May Promo",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2021, 6, 1),
        categories: ["Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: Glam0521Thumbnail,
    },
    {
        id:10,
        projectName: "Glamorama June Promo",
        subtitle: "Animations",
        dateCompleted: new Date( 2021, 7, 1),
        categories: [ "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: Glam0621Thumbnail,
    },
    {
        id:11,
        projectName: "Dancing Therapy June Promo",
        subtitle: "Poster Design and Animations",
        dateCompleted: new Date( 2021, 6, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0621,
    },
    {
        id:12,
        projectName: "Glamorama July Promo",
        subtitle: "Animations",
        dateCompleted: new Date( 2021, 8, 1),
        categories: ["Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: Glam0721Thumbnail,
    },
    {
        id:13,
        projectName: "Classico Poster",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2021, 6, 21),
        categories: ["Design"], 
        programs: ["Photoshop"],
        thumbnail: ClassicoThumbnail,
    },
    {
        id: 14,
        projectName: "Tony Trimboli Album Art",
        subtitle: "Album Art",
        dateCompleted: new Date( 2021, 6, 1),
        categories: ["Design"],
        programs: ["Photoshop", "AfterEffects"],
        thumbnail: TJAlbumArtThumbnail,
    },
    {
        id: 15,
        projectName: "GMTK Game Jam",
        subtitle: "MiniGame",
        dateCompleted: new Date( 2021, 6, 1),
        categories: ["Programming"], 
        programs: ["Unreal"],
        thumbnail: GMTKThumbnail,
    },
    ///2022
    {
        id: 16,
        projectName: "Dancing Therapy March Promo",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2022, 3, 1),
        categories: ["Design"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0322,
    },
    {
        id: 17,
        projectName: "Kittys Pickles",
        subtitle: "Logo and Brand Design",
        dateCompleted: new Date( 2022, 2, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: KittysPicklesThumbnail,
    },
    {
        id: 18,
        projectName: "Dancing Therapy April Promo",
        subtitle: "Poster Design and Animation",
        dateCompleted: new Date( 2022, 4, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0422,
    },
    {
        id: 19,
        projectName: "Glamorama March Promo",
        subtitle: "Animations",
        dateCompleted: new Date( 2022, 3, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: Glam0322Thumbnail,           
    },
    {
        id: 20, 
        projectName: "Untitiled Co-op shooter",
        subtitle: "Game Showcase",
        dateCompleted: new Date( 2022, 6, 1),
        categories: ["Programming"],
        programs: ["Unreal"],
        thumbnail: CoopShooter2022Thumbnail,
    },
    {
        id: 21, 
        projectName: "Glamorama April Promo",
        subtitle: "Animations",
        dateCompleted: new Date( 2022, 4, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: Glam0422Thumbnail,
    },
    {
        id: 23, 
        projectName: "Portfolio Website",
        subtitle: "React Project",
        dateCompleted: new Date( 2022, 5, 1),
        categories: ["Programming"],
        programs: ["React", "Visual Studio Code"],
        thumbnail: Portfolio22Thumbnail,
    },
    {
        id: 24, 
        projectName: "React Projects",
        subtitle: "React Sample Projects",
        dateCompleted: new Date( 2022, 5, 1),
        categories: ["Programming"],
        programs: ["React", "Visual Studio Code"],
        thumbnail: ReactProjects22Thumbnail,
    },
    {
        id: 25, 
        projectName: "Glamorama May Promo",
        subtitle: "Animations",
        dateCompleted: new Date( 2022, 5, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: Glam0522Thumbnail,
    },
    {
        id: 26, 
        projectName: "Dancing Therapy May Promo",
        subtitle: "Animations",
        dateCompleted: new Date( 2022, 6, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0522,
    },
    {
        id: 27, 
        projectName: "Dancing Therapy June Promo",
        subtitle: "Animations",
        dateCompleted: new Date( 2022, 6, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0622,
    },
    {
        id: 28, 
        projectName: "Dancing Therapy July Promo",
        subtitle: "Animations",
        dateCompleted: new Date( 2022, 7, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0722,
    },
    {
        id: 29, 
        projectName: "Dancing Therapy August Promo",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2022, 8, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0822,
    },
    {
        id: 30, 
        projectName: "Dancing Therapy September Promo",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2022, 9, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects"],
        thumbnail: DT0922,
    },
    {
        id: 31, 
        projectName: "Dancing Therapy April Promo",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2023, 5, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: DT0423,
    },
    {
        id: 32, 
        projectName: "Much More Music",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2022, 12, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: MMMThumbnail,
    },
    {
        id: 33, 
        projectName: "Send Return",
        subtitle: "Poster Animation",
        dateCompleted: new Date( 2023, 5, 1),
        categories: ["Design", "Animation"],
        programs: ["Blender", "AfterEffects", "Photoshop"],
        thumbnail: MilluThumbnail,
    },
    {
        id: 34, 
        projectName: "Game Blog",
        subtitle: "Blog for all my Unreal Projects",
        dateCompleted: new Date( 2023, 5, 1),
        categories: ["Design", "Programming"],
        programs: ["Unreal"],
        thumbnail: GameBlogThumbnail,
    },
    {
        id: 35, 
        projectName: "Wedding Website",
        subtitle: "Wedding RSVP list and info page",
        dateCompleted: new Date( 2023, 6, 1),
        categories: ["Design", "Programming"],
        programs: ["React", "Visual Studio Code"],
        thumbnail: WeddingWebsiteThumbnail,
    },
    {
        id: 36, 
        projectName: "Time Unlimited",
        subtitle: "Poster Design",
        dateCompleted: new Date( 2023, 7, 1),
        categories: ["Design"],
        programs: ["Photoshop"],
        thumbnail: TimeUnlimitedThumbnail,
    },
    {
        id: 37, 
        projectName: "Sea Shifters",
        subtitle: "GMTK Game Jam Entry",
        dateCompleted: new Date( 2023, 7, 1),
        categories: ["Design", "Programming"],
        programs: ["Unreal"],
        thumbnail: GMTK2023Thumbnail,
    },
    {
        id: 38, 
        projectName: "Pool Guys",
        subtitle: "Pool Cleaning Website",
        dateCompleted: new Date( 2023, 9, 1),
        categories: ["Design", "Programming"],
        programs: ["React", "Visual Studio Code"],
        thumbnail: PoolGuysThumbnail,
    },
    {
        id: 39, 
        projectName: "Parcel Pro",
        subtitle: "Postage Packing Software",
        dateCompleted: new Date( 2023, 11, 1),
        categories: ["Design", "Programming"],
        programs: ["React", "Visual Studio Code", "Next.js", "Three.js", "Python"],
        thumbnail: ParcelProThumbnail,
    },
]
