// Imports
import { Link } from "react-router-dom";
// CSS Styles
import CSS from "./Footer.module.css";


function Footer(){

   return(
    <footer id="copyright" className={CSS.footer}>
            <p>&#169; 2022  All Rights Reserved</p>
            <Link className={CSS.link} to="/contact" rel="noreferrer">Contact</Link>
    </footer>

);}

export default Footer;