// Imports

import ContactForm from "../components/form/ContactForm";

// CSS Styles

function Contact(){

   return(
<>
    <h2 className="secondary">Contact</h2>
    <hr className="breaker"/>
    <ContactForm />  
</>

);}

export default Contact;