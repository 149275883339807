// Imports
import { Link } from "react-router-dom";
import profilePicture from "../../images/global/profilePicture.webp";
import SocialLink from "../socials/SocialLink";
import { useSelector } from "react-redux";

// CSS Styles
import CSS from "./Bio.module.css";

function Bio(){
    const bSocialsVisible = useSelector(state => state.menu.bSocialsVisible);

   return(
    <div className={CSS.page}>
        <div className={CSS.profile}>
            <img className={CSS.profilePicture} src={profilePicture} alt="Profile shot" />
            <h3>Curtis Bucciol</h3>
            <ul>
                <li>Animator</li>
                <li>Designer</li>
                <li>Programmer</li>
            </ul>
            {bSocialsVisible && 
            <ul>
                <SocialLink dark type="twitter"/>
                <SocialLink dark type="youtube"/>
                <SocialLink dark type="twitch"/>             
            </ul>}
        </div>
        <div className={CSS.bio}>
            <h1>Skills</h1>
            C++, Unreal Enigne, Javascript, Typescript, HTML/CSS, Github, React, Photoshop, After Effects, Blender, Next.js, THREE.js, Redux, MongoDB, SQL
            <h1>Hi, I'm Curtis</h1>
            <p>
                {/* I'm a creative developer / software engineer from Melbourne.
                I use C++, Javascript and Unreal Engine to create high-end interactive experiences and products.
                I currently work as a freelance designer. */}
                {/* I am a Designer, Animator and Game Programmer.  */}
                Curtis Bucciol is a multifaceted developer and designer hailing from Melbourne, 
                with expertise in C++, JavaScript, HTML, Unreal Engine, and other leading technologies.  <br/>
                <br/>
                Currenty working a Lead Software Developer at the startup Parcel Pro and as a seasoned freelancer, 
                Curtis leverages his Bachelor of Design degree, majoring in 
                Digital Media Design, to craft high-end interactive experiences across various platforms. <br/>
                <br/>
                With three years of dedicated freelance design work under his belt, Curtis has honed his skills as a 
                Designer, Animator, and Game Programmer. <br/>
                <br/>
                His passion for technology and innovation is reflected in his continuous learning endeavors, 
                encompassing areas like React, Three.js, and Unreal Engine 4/5.
                <br/>   
                Currently, Curtis is working on an ambitious project, "Space Plunder," 
                a game that serves as a testament to his proficiency in C++ and Unreal Engine 4/5. 
                <br/>                <br/>
                In addition, his personal website acts as a comprehensive portfolio showcasing 
                his diverse talents and projects, ranging from animations for music videos to game creations.
                <br/>                <br/>
                Curtis's blend of creativity, technical acumen, and commitment to professional growth aligns well with roles that require effective communication with 
                technical teams and insight into cutting-edge design methodologies. 
                <br/>
                His experience working independently 
                and mastering complex programming languages equips him with the skills necessary to contribute to innovative 
                projects and startups.
            </p>
        </div>
    </div>

);}

export default Bio;