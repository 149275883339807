// Imports
import PortfolioList from "../components/portfolio/PortfolioList";

// CSS Styles

function Home(){

   return(
    <>
        <hr className="breaker"/>
        <h2 className="secondary">Latest work<br/>
        Check out more on my portfolio page</h2>
        <PortfolioList highlights amount={6}/>
    </>

);}

export default Home;