// Imports
import {useRef} from "react"
import useInput from "../../hooks/useInput";
import emailjs from '@emailjs/browser';

// CSS Styles
import CSS from './ContactForm.module.css';

function ContactForm(){
    const form = useRef();
    const {
        value: enteredName,
        isValid: nameIsValid,
        hasError: nameInputHasError,
        resetValue: nameResetValue,
        valueChangedHandler: nameChangedHandler,
        valueBlurHandler: nameBlurHandler,
    } = useInput(value => value.trim() !== "");

    const { 
        value: enteredEmail, 
        isValid: emailIsValid,
        hasError: emailInputHasError, 
        resetValue: emailResetValue,
        valueChangedHandler: emailChangedHandler, 
        valueBlurHandler: emailBlurHandler
    } = useInput(value => value.trim() !== "" && value.includes("@") && value.includes("."));

    const { 
        value: enteredMessage, 
        isValid: messageIsValid,
        hasError: messageInputHasError, 
        resetValue: messageResetValue,
        valueChangedHandler: messageChangedHandler, 
        valueBlurHandler: messageBlurHandler
    } = useInput(value => value.trim() !== "");

    let formIsValid = false;
    if(nameIsValid && emailIsValid && messageIsValid)
    {
        formIsValid = true;
    }
    function clearValues(){
        nameResetValue();
        emailResetValue();
        messageResetValue();
    }

    function formSubmitHandler(event){
        event.preventDefault();
        if(formIsValid)
        {
            emailjs.sendForm('Gmail', 'template_l0ky8qo', form.current, '38rzLU1nCNtIioyww')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
            clearValues();
        }
    };
    const nameInputClasses = nameInputHasError ? `${CSS.control} ${CSS.invalid}` : CSS.control;
    const emailInputClasses = emailInputHasError ? `${CSS.control} ${CSS.invalid}` : CSS.control;
    const messageInputClasses = messageInputHasError ? `${CSS.control} ${CSS.invalid}` : CSS.control;

    //todo: p classname = error-text is not a class

   return(
       <form onSubmit={formSubmitHandler} className={CSS.auth} ref={form}>
           <div className={nameInputClasses}>
                <label htmlFor="name">Full Name</label>
                <input value={enteredName} onBlur={nameBlurHandler} onChange={nameChangedHandler} placeholder="John Smith" type="text" id="name" name="from_name"/>
                <div className={CSS.errorMessage}>{nameInputHasError && <p>Name must not be empty</p>}</div>
           </div>
           <div className={emailInputClasses} >
                <label htmlFor='email'>Email</label>
                <input value={enteredEmail} onBlur={emailBlurHandler} onChange={emailChangedHandler} placeholder="JohnSmith@hellokitty.com" type='email' id='email' name="email" />
                <div className={CSS.errorMessage}>{emailInputHasError && <p className="error-text">Please add a valid Email</p>}</div>
            </div>
            <div className={messageInputClasses}>
                <label htmlFor='message'>Message</label>
                <textarea value={enteredMessage} onBlur={messageBlurHandler} onChange={messageChangedHandler} placeholder="Hey Lets get in touch" type='textarea' rows="4" cols="36" id='message' name="message" />
                <div className={CSS.errorMessage}>{messageInputHasError && <p className="error-text">Please add a message</p>}</div>
            </div>
            <div>
                <button className="btn" type="submit">Send</button>
            </div>
        </form>
);}

export default ContactForm;