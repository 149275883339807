// Imports
import { useRef } from "react";

// CSS Styles
import CSS from "./SubmitPortfolioPiece.module.css"


// id: 1,
// projectName: "GMTK Game Jam",
// subtitle: "MiniGame",
// dateCompleted: new Date( 2021, 6, 1),
// categories: ["Programming"], 
// programs: ["Unreal"],
// thumbnail: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages.wallpapersden.com%2Fimage%2Fdownload%2Fbeautiful-sunset-in-horizon-ocean_a2VqZ2qUmZqaraWkpJRtZWWta2Vl.jpg&f=1&nofb=1",


function SubmitPortfolioPiece(){
    const ProjectNameRef = useRef();
    const SubtitleRef = useRef();
    const DateRef = useRef();
    const ThumbnailRef = useRef();
    //Categories
    const AnimationRef = useRef();
    const DesignRef = useRef();
    const ProgrammingRef = useRef();
    //Programs
    const AfterEffectsRef = useRef();
    const VisualStudioCodeRef = useRef();
    const BlenderRef = useRef();
    const UnrealRef = useRef();
    const PhotoshopRef = useRef();

    
    function submitHandler(event){
        event.preventDefault();

        let categories = [];
        if(AnimationRef.current.checked)
        {
            categories.push("Animation")
        }  
        if(DesignRef.current.checked)
        {
            categories.push("Design")
        }
        if(ProgrammingRef.current.checked)
        {
            categories.push("Programming")
        }
        let programs = [];
        if(AfterEffectsRef.current.checked)
        {
            programs.push("");
        }


        
        console.log(categories);

        const Project = {
            projectName: ProjectNameRef.current.value,
            subtitle: SubtitleRef.current.value,
            dateCompleted: new Date(DateRef.current.value),
            categories: categories,
            programs: [],
            thumbnail: "",
        };
        console.log(Project);
        
    }

    return(
        <>
        <form onSubmit={submitHandler} className={CSS.form}>
            <div>
                <label htmlFor="ProjectName">Project Name</label>
                <input ref={ProjectNameRef} type="text" id="ProjectName"/>
            </div>
            <div>
                <label htmlFor="Subtitle">Subtitle</label>
                <input ref={SubtitleRef} type="text" id="Subtitle"/>
            </div>
            <div>
                <label htmlFor='date'>Date Completed</label>
                <input ref={DateRef} type='date' id='date' />
            </div>
            <div>
                <label htmlFor="Animation">Animation</label>
                <input ref={AnimationRef} type="checkbox" id="Animation" name="Animation" value="Animation" />
                <label htmlFor="Design">Design</label>
                <input ref={DesignRef} type="checkbox" id="Design" name="Design" value="Design" />
                <label htmlFor="Programming">Programming</label>
                <input ref={ProgrammingRef} type="checkbox" id="Programming" name="Programming" value="Programming" />
            </div>
            <div>
                <label htmlFor="Programs">Programs</label>
                <label htmlFor="AfterEffects">After Effects</label>
                <input ref={AfterEffectsRef} type="checkbox" id="AfterEffects" name="AfterEffects" value="AfterEffects" />
                <label htmlFor="Unreal">Unreal</label>
                <input type="checkbox" id="Unreal" name="Unreal" value="Unreal" />
                <label htmlFor="Photoshop">Photoshop</label>
                <input type="checkbox" id="Photoshop" name="Photoshop" value="Photoshop" />
                <label htmlFor="VisualStudioCode">Visual Studio Code</label>
                <input type="checkbox" id="VisualStudioCode" name="VisualStudioCode" value="VisualStudioCode" />
                <label htmlFor="Blender">Blender</label>
                <input type="checkbox" id="Blender" name="Blender" value="Blender" />
            </div>
            <div>
                <label htmlFor="Thumbnail">Thumbnail</label>
                {/* <input ref={ThumbnailRef} type="file" id="Thumbnail"/> */}
                <input ref={ThumbnailRef} type="url" id="Thumbnail"/>

            </div>

            <button type="submit">Submit</button>
    </form>
        
        
        
        </>

);}

export default SubmitPortfolioPiece;